import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components"
import Select from "../../components/Select"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import { useEffect, useState } from "react"
import Comm from "../../Comm"
import ChargerType from "../../types/ChargerType"
import Modal from "../../components/Modal"
import Slider from "../../components/Slider"
import Switch from "../../components/Switch"
import Notification from "../../components/Notification"
import Button from "../../components/Button"
import Icon from "../../Icon"
import Navigator from "../../components/Navigator"
import LoaderDots from "../../components/LoaderDots"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import InputWithLabel from "../../components/InputWithLabel"

interface ChargerValuesType {
  power_stat: {
    current1: number,
    current2: number,
    current3: number,
    power1: number,
    power2: number,
    power3: number,
    voltage1: number,
    voltage2: number,
    voltage3: number,
    temp_uc: number,
    temp_pow: number,
    temp_pi: number,
    hum: number,
    dlm_valid: boolean,
    dlm_current1: number,
    dlm_current2: number,
    dlm_current3: number
  }
  config: {
    wifi: {
      mode: number,
      ssid: string,
      passwd: string,
      ap_mode_ssid: string
    },
    ethernet: {
      mode: number,
      ip_address: string,
      netmask: string,
      gateway: string,
      dns: string
    },
    gsm: {
      mode: number,
      apn: string,
      apn_uname: string,
      apn_pwd: string,
      sim_pin: string,
      pin_error: boolean,
      nw_reg_to_sec: number,
      IDs: {
        iccid: string,
        imei: string,
        imsi: string,
        revision: string
      }
    },
    display: {
      enabled: boolean,
      night_mode_en: boolean,
      night_period_sp: number,
      night_period_st: number,
      on_seconds: number,
      rotate180: boolean,
      language: number,
      eyes_en: boolean,
      tap_rfid_interval: number,
      brightness: number,
      scroll_speed: number
    },
    front_led: {
      enabled: boolean,
      brightness: number,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number
    },
    rear_led: {
      enabled: boolean,
      brightness: number,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number,
      color_r: number,
      color_g: number,
      color_b: number
    },
    sound: {
      enabled: boolean,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number
    },
    localisation: {
      gmt_offset: number,
      zoneinfo: string
    },
    power: {
      phase_order: number,
      curr_usr_limit: number,
      curr_night_limit: number,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number,
      mid_meter_en: boolean,
      mid_meter_type: number,
      dlm_mode: number,
      dlm_meter_type: number,
      dlm_dyn_curr_lim: number,
      dlm_dyn_specific: boolean,
      dlm_dyn_curr1_lim: number,
      dlm_dyn_curr2_lim: number,
      dlm_dyn_curr3_lim: number,
      dlm_dyn_ud_time: number,
      dlm_dyn_dd_time: number,
      dlm_ecogrn_id_time: number,
      dlm_grid_u_stop: number,
      dlm_grid_u_min: number,
      dlm_grid_u_max: number,
      dlm_grid_t_up: number,
      dlm_grid_t_dn: number,
      curr_hw_limit: number,
      mid_meter_state: number,
      dlm_meter_state: number,
      night_mode: boolean,
      curr_hw_limit_min: number
    },
    ip_485: {
      ip: string,
      port: number,
      meter1: {
        type: number,
        usage: number,
        sn: string
      },
      meter2: {
        type: number,
        usage: number,
        sn: string
      }
    },
    pi2pi_netw: {
      role: number,
      port: number,
      master1_ip: string,
      master2_ip: string,
      top_master1_ip: string,
      top_master2_ip: string,
      segment_id: number,
      ps_enabled: boolean,
      ps_autonom_curr: number
    }
  },
  rsp_stat: {
    wifi?: {
      mode: number,
      quality: string,
      rssi: number,
      mac: string,
      ip: string,
      ssid: string,
      ap_mode_ssid: string
    },
    ethernet?: {
      mode: number,
      ip_address: string,
      netmask: string,
      gateway: string,
      dns: string,
      hostname: string,
      mac_address: string
    },
    gsm?: {
      mode: number,
      status: string,
      imei: string,
      iccid: string,
      uptime: number,
      rssi: string,
      rx_bytes: string,
      tx_bytes: string,
      operator: string
    }
  }
}

const Container = styled.div`
  width: 100%;
  height: calc(90vh - 70px);
  overflow-y: scroll;
`

const SettingContainer = styled.div<{ $color?: string, $noAnimation?: boolean, $noFlex?: boolean }>`
  background-color: ${props => props.$color ? props.theme.colors[props.$color] : 'transparent'};
  padding: 24px;
  margin: 8px;
  display: ${props => props.$noFlex ? 'block' : 'flex'};
  border-radius: 24px;
  position: relative;
  cursor: pointer;

  &:active {
    ${props => !props.$noAnimation && `
      transform: scale(.985);
    `}
  }
`

const SettingIconContainer = styled.div<{ $small?: boolean }>`
  width: ${props => props.$small ? '25px' : '40px'};
  height: ${props => props.$small ? '25px' : '40px'};
  padding: ${props => props.$small ? '7.5px' : '0px'};
`

const SettingLabel = styled.p<{ $white?: boolean, $small?: boolean }>`
  font-weight: 700;
  font-size: ${props => props.$small ? '14px' : '18px'};
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-top: ${props => props.$small ? '10px' : '8px'};
  margin-left: 12px;
`

const SettingArrow = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 24px;
  margin-top: 10px;
`

const DividerLineColor = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #2A6FF4, #E2594B);
`

const DividerLineGray = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background-color: ${props => props.theme.colors.gray_300};
`

const CustomCommandsIconBackground = styled.div`
  background-color: ${props => props.theme.colors.dark};
  height: 40px;
  width: 40px;
  border-radius: 10px;
`

const CustomCommandsTextInput = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  outline: none;
  border: 1px solid ${props => props.theme.colors.gray_300};
  border-radius: 8px;
`

const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
  width: 20vw;
`

const ModalSubcategoryTitle = styled.p` // When there is a back icon before the label
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
  width: calc(20vw - 22px - 8px);
`

const ModalContainer = styled.div`
  padding: 24px;
  height: 85vh;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const ModalButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 10px);
  background-color: ${props => props.theme.colors.background};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`

const ModalButton = styled.p<{ $hasBg?: boolean }>`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.$hasBg ? props.theme.colors.white : props.theme.colors.gray_500};
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 16px;
  width: 20%;
  text-align: center;
  border-radius: 12px;
  float: right;
  background-color: ${props => props.$hasBg ? props.theme.colors.dark : 'none'};
  cursor: pointer;
`

const ModalDetailTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
`

const ModalDetailTitleThin = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
`

const ModalDetailDivider = styled.div<{ $isColor?: boolean }>`
  height: ${props => props.$isColor ? '2px' : '1px'};
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: ${props => props.$isColor ? 'none' : props.theme.colors.gray_300};
  background-image: ${props => props.$isColor ? 'linear-gradient(to right, #2A6FF4, #E2594B)' : 'none'};
`

const DetailedStatsValue = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const DetailedStatsValueUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-left: 2px;
`

const TimeInput = styled.input<{ $right?: boolean }>`
  border: 1px solid ${props => props.theme.colors.gray_400};
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  outline: none;
  position: ${props => props.$right ? 'absolute' : 'relative'};
  right: ${props => props.$right ? '20px' : 'auto'};
`

const ColorInput = styled.input`
  width: 32.5px;
  height: 32.5px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &::-webkit-color-swatch {
    border-radius: 15px;
    border: 2px solid ${props => props.theme.colors.gray_400};
  }

  &::-moz-color-swatch {
    border-radius: 30px;
    border: 2px solid ${props => props.theme.colors.gray_400};
  }
`

const SmallModalContainer = styled.div`
  padding: 24px;
`

const SmallModalLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

const SmallModalButtonContainer = styled.div`
  width: 15vw;
  display: flex;
`

const ConnectionActiveLabel = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  position: absolute;
  right: 52px;
  margin-top: 10px;
`

export default function Settings({ charger, isAdmin }: { charger: ChargerType, isAdmin: boolean }) {
  const { t } = useTranslation()

  const [chargerValues, setChargerValues] = useState<ChargerValuesType>()
  const [notification, setNotification] = useState<string>('')
  const [isSendingBottomRowCommand, setIsSendingBottomRowCommand] = useState<string | null>()
  const [isChargerBlocked, setIsChargerBlocked] = useState<boolean>()

  const [customCommandInputValue, setCustomCommandInputValue] = useState<string>('')
  const [customCommandDropdownOptions] = useState<{ [key: string]: { msg_type?: number, sub_cmd?: number, cmd?: string } }>({
    '': {},
    'Force upgrade check': { msg_type: 15, sub_cmd: 1 },
    'Force log upload': { msg_type: 15, sub_cmd: 3 },
    'Clear init': { msg_type: 15, sub_cmd: 4 },
    'Delete CDR records (except last)': { msg_type: 15, sub_cmd: 6 },
    'Calibrate EVSE': { msg_type: 15, sub_cmd: 8 },
    'Reset factory defaults': { msg_type: 15, sub_cmd: 9 },
    'Reboot PI': { msg_type: 15, sub_cmd: 10 },
    'Force firmware reflash': { msg_type: 15, sub_cmd: 11 },
    'Software restart (without reboot)': { msg_type: 15, sub_cmd: 12 },
    'Force firmware reset': { msg_type: 15, sub_cmd: 13 },
    'GSM Modem test': { msg_type: 15, sub_cmd: 15 },
    'Toggle PS display': { msg_type: 15, sub_cmd: 17 },
    'Drop Logs': { msg_type: 15, sub_cmd: 18 },
    'Spotlight Display': { msg_type: 15, sub_cmd: 19 },
    'Reset Log Limit': { msg_type: 15, sub_cmd: 20 },
    'Fan ON': { msg_type: 15, sub_cmd: 21 },
    'Fan OFF': { msg_type: 15, sub_cmd: 22 },
    'Speaker TEST': { msg_type: 15, sub_cmd: 23 },
    'Clear Lifetime': { msg_type: 15, sub_cmd: 26 },
    'Debug Charge State': { msg_type: 15, sub_cmd: 27 },
    'Get Charge State': { msg_type: 15, sub_cmd: 28 },
    'Force V1 Upgrade': { msg_type: 15, sub_cmd: 29 },
    'Get Product Info': { msg_type: 12 },
    'Get Config': { msg_type: 20 },
    'Command to FW': { msg_type: 75, cmd: "$GX2" },
    'Get IP meter current': { msg_type: 15, sub_cmd: 30 }
  })

  const [isShowingDetailedStats, setIsShowingDetailedStats] = useState<boolean>(false)
  const [isShowingDisplaySettings, setIsShowingDisplaySettings] = useState<boolean>(false)
  const [isShowingLightSettings, setIsShowingLightSettings] = useState<boolean>(false)
  const [isShowingSpeakerSettings, setIsShowingSpeakerSettings] = useState<boolean>(false)
  const [isShowingPowerOptions, setIsShowingPowerOptions] = useState<boolean>(false)
  const [isShowingConnections, setIsShowingConnections] = useState<boolean | string>(false) // true / false / wifi / ethernet / gsm
  const [isShowingMidMetering, setIsShowingMidMetering] = useState<boolean>(false)
  const [isShowingIpMeterSettings, setIsShowingIpMeterSettings] = useState<boolean>(false)


  const [isShowingDebugLevels, setIsShowingDebugLevels] = useState<boolean>(false)
  const [debugLevels, setDebugLevels] = useState<{ name: string, value: number }[] | null>()

  async function handleSendCustomCommand() {
    if (customCommandInputValue != '') {
      try {
        const command = JSON.parse(customCommandInputValue)
        const response = await Comm('/api-websocket/mobile', {
          method: "POST",
          headers: {
            "Charger_id": charger.chargerId,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(command)
        })
        if (response.ok) {
          setNotification(await response.json())
        } else {
          setNotification(await response.text())
        }
      } catch (err) {
        setNotification('Invalid JSON')
      }
    } else {
      setNotification('Can not be empty')
    }
  }

  async function fetchCMD_GET_POWER_STAT() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 21 })
    })
    const result = await response.json()
    console.log(21, result)
    return result.power_stat
  }

  async function fetchCMD_GET_CONFIG() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 20 })
    })
    const result = await response.json()
    console.log(20, result)
    return result
  }

  async function fetchCMD_GET_RSP_STAT() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 22 })
    })
    const result = await response.json()
    console.log(22, result)
    return result
  }

  async function fetchCMD_GET_DEBUG_LEVEL() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 77 })
    })
    const result = await response.json()

    const resultObject: { name: string, value: number }[] = []
    for (let i = 0; i < result.module_names.length; i++) {
      resultObject.push({ name: result.module_names[i], value: result.debug_levels[i] })
    }

    setDebugLevels(resultObject)
  }

  async function fetchValues() {
    try {
      const power_stat = await fetchCMD_GET_POWER_STAT()
      const config = await fetchCMD_GET_CONFIG()
      const rsp_stat = await fetchCMD_GET_RSP_STAT()
      setChargerValues({ power_stat, config, rsp_stat })
      setIsChargerBlocked(await fetchIsChargerBlocked())
    } catch (e) { console.log(e, 'Charger probably offline') }
  }

  useEffect(() => {
    fetchValues()
  }, [])

  async function fetchIsChargerBlocked() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 3 })
    })
    if (response.ok) {
      const result = await response.json()
      return result.evse_state == 18
    }
  }

  // TODO: This is a very stupid solution but I could not come up with a better one at the time
  function updateValueInChargerValues(keys: string, value: string | number | boolean) {
    if (chargerValues) {
      const newChargerValues = structuredClone(chargerValues)
      const path = keys.split('.')
      switch (path.length) {
        case 2:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]] = value
          break;
        case 3:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]][path[2]] = value
          break;
        case 4:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]][path[2]][path[3]] = value
          break;
        case 5:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]][path[2]][path[3]][path[4]] = value
          break;
      }
      setChargerValues(newChargerValues)
    }
  }

  async function handleSaveDisplayChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "display": chargerValues.config.display })
      })
      setIsShowingDisplaySettings(false)
    }
  }

  async function handleSaveLightChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "front_led": chargerValues.config.front_led, "rear_led": chargerValues.config.rear_led })
      })
      setIsShowingLightSettings(false)
    }
  }

  async function handleSaveSpeakerChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "sound": chargerValues.config.sound })
      })
      setIsShowingSpeakerSettings(false)
    }
  }

  async function handleSaveMidMeteringChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "power": chargerValues.config.power })
      })
      setIsShowingMidMetering(false)
    }
  }

  async function handleSavePowerOptionChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "power": chargerValues.config.power })
      })
      setIsShowingPowerOptions(false)
    }
  }

  async function handleSaveIpMeterSettingsChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "ip_485": chargerValues.config.ip_485 })
      })
      setIsShowingIpMeterSettings(false)
    }
  }

  function secondsToTimeString(seconds: number) {
    const hours = Math.floor(seconds / 3600)
    const minutes = (seconds - (hours * 3600)) / 60
    return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`
  }

  function timeStringToSeconds(time: string) {
    const hours = time.split(':')[0]
    const minutes = time.split(':')[1]
    return (parseInt(hours) * 3600) + (parseInt(minutes) * 60)
  }

  function rgbToHex(r: number, g: number, b: number) {
    const componentToHex = (c: number) => {
      const hex = c.toString(16)
      return hex.length === 1 ? "0" + hex : hex
    }
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
  }

  function hexToRgb(hex: string) {
    hex = hex.replace(/^#/, '')
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return { r, g, b }
  }

  async function handleSendBottomRowCommand() {
    if (isSendingBottomRowCommand) {
      const commandToSend = (() => {
        switch (isSendingBottomRowCommand) {
          case 'logUpload': return customCommandDropdownOptions['Force log upload']
          case 'calibrateEvse': return customCommandDropdownOptions['Calibrate EVSE']
          case 'getProductInfo': return customCommandDropdownOptions['Get Product Info']
          case 'getConfig': return customCommandDropdownOptions['Get Config']
          case 'hardReboot': return customCommandDropdownOptions['Reboot PI']
          case 'softReset': return customCommandDropdownOptions['Software restart (without reboot)']
          case 'deleteCDRs': deleteCDRs(); return
          // Not a bottom row command but does the same
          case 'forceUpdate': return customCommandDropdownOptions['Force upgrade check']
        }
      })()

      if (commandToSend) {
        const response = await Comm('/api-websocket/mobile', {
          method: "POST",
          headers: {
            "Charger_id": charger.chargerId,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(commandToSend)
        })
        if (response.ok) {
          setNotification(await response.json())
        } else {
          setNotification(await response.text())
        }
      }
      setIsSendingBottomRowCommand(null)
    }
  }

  async function deleteCDRs() {
    const response = await Comm('/api-cdr/cdrs/delete', {
      method: "DELETE",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      }
    })
    setNotification(await response.text())
  }

  async function toggleBlockCharger(isAvailable: boolean) {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 62, "available": isAvailable })
    })
  }

  async function handleUpdateDebugLevel(module: number, level: number) {
    // Prevent setting the module to its current value
    if (debugLevels && debugLevels[module].value != level) {

      const response = await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 76, "module": module, "level": level })
      })

      debugLevels[module].value = level
      setDebugLevels(debugLevels)

      if (!response.ok) {
        setNotification("Something went wrong while setting debug level, charger might be offline")
      }
    }
  }

  async function handleResetDebugLevelsToDefault() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 76, "reset": true })
    })


    if (!response.ok) {
      setNotification("Something went wrong while setting debug level, charger might be offline")
    }

    fetchCMD_GET_DEBUG_LEVEL()
  }

  // Fetch power stats every 5 seconds if the detailed stats modal is open
  useEffect(() => {
    const powerStatsRefresh = setTimeout(async () => {
      if (isShowingDetailedStats && chargerValues) {
        setChargerValues({ ...chargerValues, power_stat: await fetchCMD_GET_POWER_STAT() })
      }
    }, 5000)

    return () => clearTimeout(powerStatsRefresh)
  }, [chargerValues, isShowingDetailedStats])

  return (
    <>
      {notification && (
        <Notification setState={setNotification} label={JSON.stringify(notification)} />
      )}
      {isSendingBottomRowCommand && (
        <Modal onEscape={() => setIsSendingBottomRowCommand(null)}>
          <SmallModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsSendingBottomRowCommand(null)} />
            </ModalCloseButtonContainer>
            <SmallModalLabel>{`${t('charger.areYouSureAboutSending')}:`}</SmallModalLabel>
            <SmallModalLabel>{t(`charger.${isSendingBottomRowCommand}`)}</SmallModalLabel>
            <WhiteSpace height="32px" />
            <SmallModalButtonContainer>
              <Button onclick={handleSendBottomRowCommand} label={t('charger.send')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsSendingBottomRowCommand(null)} label={t('charger.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.settings')} />
      <Container>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="dark" onClick={() => setIsShowingDetailedStats(true)}>
                  <SettingIconContainer>
                    <Icon path="settings.powerDetails" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel $white>{t('charger.powerDetails')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" color="white" />
                  </SettingArrow>
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="gray_200" onClick={() => setIsShowingDisplaySettings(true)}>
                  <SettingIconContainer>
                    <Icon path="settings.display" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{t('charger.display')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="warning" onClick={() => setIsShowingLightSettings(true)}>
                  <SettingIconContainer>
                    <Icon path="settings.lights" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{t('charger.lights')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="primary" onClick={() => setIsShowingSpeakerSettings(true)}>
                  <SettingIconContainer>
                    <Icon path="settings.speakerOptions" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{t('charger.speakerOptions')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="dark" onClick={() => setIsShowingPowerOptions(true)}>
                  <SettingIconContainer>
                    <Icon path="settings.powerOptions" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel $white>{t('charger.powerOptions')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" color="white" />
                  </SettingArrow>
                </SettingContainer>
              </td>
            </tr>
            <tr>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="gray_200" onClick={() => setIsShowingConnections(true)}>
                  <SettingIconContainer>
                    <Icon path="settings.connections" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{t('charger.connections')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="dark" onClick={() => setIsShowingMidMetering(true)}>
                  <SettingIconContainer>
                    <Icon path="settings.midMetering" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel $white>{t('charger.midMetering')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" color="white" />
                  </SettingArrow>
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="gray_200" style={{ filter: 'grayscale(1)', pointerEvents: 'none' }}>
                  <SettingIconContainer>
                    <Icon path="settings.ocppSettings" color="gray_900" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{t('charger.ocppSettings')}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="gray_300" style={{ cursor: 'auto' }}>
                  <SettingIconContainer>
                    <Icon path="chargers.chargerStatusBlocked" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{t('charger.chargerEnabled')}</SettingLabel>
                  <div style={{ position: "absolute", right: "24px", marginTop: "4px" }}>
                    {isChargerBlocked != undefined ? <>
                      <Switch toggled={!isChargerBlocked} setToggled={(value) => { setIsChargerBlocked(!value); toggleBlockCharger(value) }} isBorder />
                    </> : <>
                      <p style={{ fontWeight: '700', fontSize: '14px', marginTop: "4px" }}><LoaderDots /></p>
                    </>}
                  </div>
                </SettingContainer>
              </td>
            </tr>
          </tbody>
        </table>
        {isAdmin && <>
          <WhiteSpace height={'32px'} />
          <DividerLineColor />
          <WhiteSpace height={'32px'} />
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '20%' }}>
                  <SettingContainer $color="white" onClick={() => setIsSendingBottomRowCommand('forceUpdate')}>
                    <SettingIconContainer>
                      <Icon path="general.refresh" color="gray_900" width="70%" height="70%" marginleft="15%" margintop="15%" />
                    </SettingIconContainer>
                    <SettingLabel>{t('charger.forceUpdate')}</SettingLabel>
                    <SettingArrow>
                      <Icon path="general.forwardArrow" width="100%" height="100%" />
                    </SettingArrow>
                  </SettingContainer>
                </td>
                <td style={{ width: '20%' }}>
                  <SettingContainer $color="white" onClick={() => { setIsShowingDebugLevels(true); fetchCMD_GET_DEBUG_LEVEL() }}>
                    <SettingIconContainer>
                      <Icon path="general.wrench" color="gray_900" width="70%" height="70%" marginleft="15%" margintop="15%" />
                    </SettingIconContainer>
                    <SettingLabel>{t('charger.debugLevels')}</SettingLabel>
                    <SettingArrow>
                      <Icon path="general.forwardArrow" width="100%" height="100%" />
                    </SettingArrow>
                  </SettingContainer>
                </td>
                <td style={{ width: '20%' }}>
                  <SettingContainer $color="white" onClick={() => setIsShowingIpMeterSettings(true)}>
                    <SettingIconContainer>
                      <Icon path="settings.ethernet" color="gray_900" width="70%" height="70%" marginleft="15%" margintop="15%" />
                    </SettingIconContainer>
                    <SettingLabel>{t('charger.ipMeterSettings')}</SettingLabel>
                    <SettingArrow>
                      <Icon path="general.forwardArrow" width="100%" height="100%" />
                    </SettingArrow>
                  </SettingContainer>
                </td>
                <td style={{ width: '20%' }}></td>
                <td style={{ width: '20%' }}></td>
              </tr>
            </tbody>
          </table>
          <WhiteSpace height={'32px'} />
          <div style={{ minWidth: '30vw', display: 'inline-block' }}>
            <SettingContainer $noFlex $noAnimation $color="white">
              <div style={{ display: 'flex' }}>
                <CustomCommandsIconBackground>
                  <SettingIconContainer>
                    <Icon path="settings.gear" color="gray_300" width="60%" height="60%" marginleft="20%" margintop="20%" />
                  </SettingIconContainer>
                </CustomCommandsIconBackground>
                <SettingLabel>{t('charger.customCommands')}</SettingLabel>
                <WhiteSpace width='48px' />
                <div style={{ position: 'absolute', right: '48px', width: '40%', zIndex: '100' }}>
                  <Select width={'100%'} label={t('charger.command')} isAbsolute options={Object.keys(customCommandDropdownOptions)} handleSelect={(value) => setCustomCommandInputValue(JSON.stringify(customCommandDropdownOptions[value]))} />
                </div>
              </div>
              <WhiteSpace height='24px' />
              <CustomCommandsTextInput value={customCommandInputValue != '{}' ? customCommandInputValue : ''} onInput={(e) => setCustomCommandInputValue((e.target as HTMLInputElement).value)} />
              <WhiteSpace height='24px' />
              <div style={{ display: 'inline-block', width: '100%' }}>
                <div style={{ display: 'flex' }}>
                  <ButtonWithIcon label={t('charger.send')} onClick={handleSendCustomCommand} />
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Navigator to='https://voltie.atlassian.net/wiki/spaces/VOLTIE/pages/392003585/Frontendr+l+kiadhat+parancs+mint+k' newTab>
                      <ButtonWithIcon label={t('charger.commandSamples')} onClick={() => { }} backgroundColor="gray_900" />
                    </Navigator>
                  </div>
                </div>
              </div>
            </SettingContainer>
          </div>
          <WhiteSpace height={'32px'} />
          <DividerLineGray />
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '14.2%' }}>
                  <SettingContainer onClick={() => setIsSendingBottomRowCommand('logUpload')}>
                    <SettingIconContainer $small>
                      <Icon path="general.log" color="gray_900" width="100%" height="100%" />
                    </SettingIconContainer>
                    <SettingLabel $small>{t('charger.logUpload')}</SettingLabel>
                  </SettingContainer>
                </td>
                <td style={{ width: '14.2%' }}>
                  <SettingContainer onClick={() => setIsSendingBottomRowCommand('calibrateEvse')}>
                    <SettingIconContainer $small>
                      <Icon path="settings.calibrateEvse" color="gray_900" width="100%" height="100%" />
                    </SettingIconContainer>
                    <SettingLabel $small>{t('charger.calibrateEvse')}</SettingLabel>
                  </SettingContainer>
                </td>
                <td style={{ width: '14.2%' }}>
                  <SettingContainer onClick={() => setIsSendingBottomRowCommand('getProductInfo')}>
                    <SettingIconContainer $small>
                      <Icon path="settings.gear" color="gray_900" width="100%" height="100%" />
                    </SettingIconContainer>
                    <SettingLabel $small>{t('charger.getProductInfo')}</SettingLabel>
                  </SettingContainer>
                </td>
                <td style={{ width: '14.2%' }}>
                  <SettingContainer onClick={() => setIsSendingBottomRowCommand('getConfig')}>
                    <SettingIconContainer $small>
                      <Icon path="general.wrench" color="gray_900" width="100%" height="100%" />
                    </SettingIconContainer>
                    <SettingLabel $small>{t('charger.getConfig')}</SettingLabel>
                  </SettingContainer>
                </td>
                <td style={{ width: '14.2%' }}>
                  <SettingContainer onClick={() => setIsSendingBottomRowCommand('hardReboot')}>
                    <SettingIconContainer $small>
                      <Icon path="general.wrench" color="gray_900" width="100%" height="100%" />
                    </SettingIconContainer>
                    <SettingLabel $small>{t('charger.hardReboot')}</SettingLabel>
                  </SettingContainer>
                </td>
                <td style={{ width: '14.2%' }}>
                  <SettingContainer onClick={() => setIsSendingBottomRowCommand('softReset')}>
                    <SettingIconContainer $small>
                      <Icon path="general.refresh" color="gray_900" width="100%" height="100%" />
                    </SettingIconContainer>
                    <SettingLabel $small>{t('charger.softReset')}</SettingLabel>
                  </SettingContainer>
                </td>
                <td style={{ width: '14.2%' }}>
                  <SettingContainer onClick={() => setIsSendingBottomRowCommand('deleteCDRs')}>
                    <SettingIconContainer $small>
                      <Icon path="general.trash" color="gray_900" width="100%" height="100%" />
                    </SettingIconContainer>
                    <SettingLabel $small>{t('charger.deleteCDRs')}</SettingLabel>
                  </SettingContainer>
                </td>
              </tr>
            </tbody>
          </table>
          <DividerLineGray />
        </>}
      </Container>





      {isShowingDetailedStats && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingDetailedStats(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingDetailedStats(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.powerDetails')}</ModalTitle>
            <WhiteSpace height="34px" />
            <div style={{ overflowY: 'scroll', maxHeight: "calc(85vh - 100px)" }}>
              {[
                { value1: chargerValues.power_stat.dlm_current1, value2: chargerValues.power_stat.dlm_current2, value3: chargerValues.power_stat.dlm_current3, unit: 'A', title: <><Icon path="settings.amper" color="primary" width="24px" height="24px" marginleft="4px" marginright="4px" /><ModalDetailTitleThin>/</ModalDetailTitleThin><Icon path="settings.home" width="24px" height="24px" marginleft="4px" marginright="4px" /><ModalDetailTitle>{t('charger.amperPhase')}</ModalDetailTitle></> },
                { value1: chargerValues.power_stat.current1, value2: chargerValues.power_stat.current2, value3: chargerValues.power_stat.current3, unit: 'A', title: <><Icon path="settings.amper" color="primary" width="24px" height="24px" marginleft="4px" marginright="4px" /><ModalDetailTitleThin>/</ModalDetailTitleThin><Icon path="general.charger" width="16px" height="16px" marginleft="8px" marginright="8px" margintop="4px" /><ModalDetailTitle>{t('charger.amperPhase')}</ModalDetailTitle></> },
                { value1: chargerValues.power_stat.power1, value2: chargerValues.power_stat.power2, value3: chargerValues.power_stat.power3, unit: 'kW', title: <><Icon path="chargers.power" color="warning" width="24px" height="24px" marginleft="4px" marginright="4px" /><ModalDetailTitle>{t('charger.powerPhase')}</ModalDetailTitle></> },
                { value1: +chargerValues.power_stat.voltage1.toFixed(1), value2: +chargerValues.power_stat.voltage2.toFixed(1), value3: +chargerValues.power_stat.voltage3.toFixed(1), unit: 'V', title: <><Icon path="settings.voltage" color="warning" width="24px" height="24px" marginleft="4px" marginright="4px" /><ModalDetailTitle>{t('charger.voltagePhase')}</ModalDetailTitle></> },
                { value1: chargerValues.power_stat.temp_pow, value2: chargerValues.power_stat.temp_uc, value3: chargerValues.power_stat.temp_pi, unit: '°C', title: <><Icon path="settings.thermometer" color="danger" width="24px" height="24px" marginleft="4px" marginright="4px" /><ModalDetailTitle>{t('charger.temperature')}</ModalDetailTitle><WhiteSpace width='8px' /><ModalDetailTitleThin>PW / UC / PI</ModalDetailTitleThin></> },
                { value1: chargerValues.power_stat.hum, unit: '%', title: <><Icon path="settings.humidity" color="primary" width="24px" height="24px" marginleft="4px" marginright="4px" /><ModalDetailTitle>{t('charger.humidity')}</ModalDetailTitle></> }
              ].map(detail => (
                <>
                  <div style={{ display: 'flex' }}>{detail.title}</div>
                  <WhiteSpace height="8px" />
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '33.3%', textAlign: 'left' }}>
                          <DetailedStatsValue>{detail.value1}<DetailedStatsValueUnit>{detail.unit}</DetailedStatsValueUnit></DetailedStatsValue>
                        </td>
                        {detail.value2 != null && <td style={{ width: '33.3%', textAlign: 'center' }}>
                          <DetailedStatsValue>{detail.value2}<DetailedStatsValueUnit>{detail.unit}</DetailedStatsValueUnit></DetailedStatsValue>
                        </td>}
                        {detail.value3 != null && <td style={{ width: '33.3%', textAlign: 'right' }}>
                          <DetailedStatsValue>{detail.value3}<DetailedStatsValueUnit>{detail.unit}</DetailedStatsValueUnit></DetailedStatsValue>
                        </td>}
                      </tr>
                    </tbody>
                  </table>
                  <ModalDetailDivider />
                </>
              ))}
            </div>
            <ModalButtonContainer>
              <ModalButton onClick={async () => setChargerValues({ ...chargerValues, power_stat: await fetchCMD_GET_POWER_STAT() })} $hasBg>{t('charger.refresh')}</ModalButton>
              <ModalButton onClick={() => setIsShowingDetailedStats(false)}>{t('charger.close')}</ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </Modal>
      )}





      {isShowingDisplaySettings && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingDisplaySettings(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingDisplaySettings(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.displaySettings')}</ModalTitle>
            <WhiteSpace height="34px" />
            <Select handleSelect={(value) => updateValueInChargerValues('config.display.language', value == 'English' ? 0 : 1)} width="calc(100% - 20px)" icon={chargerValues.config.display.language == 0 ? "chargers.flagUk" : "chargers.flagHun"} label={t('charger.language')} options={chargerValues.config.display.language == 0 ? ['English', 'Magyar'] : ['Magyar', 'English']} isAbsolute />
            <WhiteSpace height="28px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.displayEnabled')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.display.enabled} setToggled={(value) => updateValueInChargerValues('config.display.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.eyesEnabled')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.display.eyes_en} setToggled={(value) => updateValueInChargerValues('config.display.eyes_en', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.nightmodeEnabled')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.display.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.display.night_mode_en', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <ModalDetailTitle>{t('charger.textScrollingSpeed')}</ModalDetailTitle>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.display.scroll_speed} setValue={(value) => updateValueInChargerValues('config.display.scroll_speed', value)} min={1} max={100} step={1} numberOfDots={5} width="100%" firstLabel={t('charger.slow')} lastLabel={t('charger.fast')} />
            <WhiteSpace height="24px" />
            <ModalDetailDivider />
            <WhiteSpace height="8px" />
            <ModalDetailTitle>{t('charger.brightness')}</ModalDetailTitle>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.display.brightness} setValue={(value) => updateValueInChargerValues('config.display.brightness', value)} min={10} max={100} step={1} numberOfDots={5} width="100%" firstImg="settings.dim" lastImg="settings.bright" firstImgColor="gray_500" lastImgColor="gray_500" />
            <WhiteSpace height="24px" />
            <ModalDetailDivider />
            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.displayStaysActive')}</ModalDetailTitle>
              <WhiteSpace width="4px" />
              <ModalDetailTitleThin>{`(${t('charger.minute')})`}</ModalDetailTitleThin>
            </div>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.display.on_seconds == 0 ? 600 : chargerValues.config.display.on_seconds} setValue={(value) => updateValueInChargerValues('config.display.on_seconds', value == 600 ? 0 : value)} min={60} max={600} step={1} numberOfDots={11} width="100%" dotLabels={['1', '2', '3', '4', '5', '6', '7', '8', '9', '∞']} />

            <ModalButtonContainer>
              <ModalButton onClick={handleSaveDisplayChanges} $hasBg>{t('charger.save')}</ModalButton>
              <ModalButton onClick={() => setIsShowingDisplaySettings(false)}>{t('charger.cancel')}</ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </Modal>
      )}





      {isShowingLightSettings && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingLightSettings(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingLightSettings(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.lightSettings')}</ModalTitle>
            <WhiteSpace height="34px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.frontLight')}</ModalDetailTitle>
              <WhiteSpace width='8px' />
              <ModalDetailTitleThin>{`(${t('charger.mouth')})`}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.front_led.enabled} setToggled={(value) => updateValueInChargerValues('config.front_led.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="42px" />
            <Slider value={chargerValues.config.front_led.brightness} setValue={(value) => updateValueInChargerValues('config.front_led.brightness', value)} min={10} max={100} step={1} numberOfDots={5} width="100%" firstImg="settings.dim" lastImg="settings.bright" firstImgColor="gray_500" lastImgColor="gray_500" />
            <WhiteSpace height="32px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.turnedOffPeriod')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.front_led.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.front_led.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.front_led.night_mode_en && (
              <>
                <WhiteSpace height="18px" />
                <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                <WhiteSpace height="8px" />
                <TimeInput type="time" value={secondsToTimeString(chargerValues.config.front_led.night_period_st)} onChange={(e) => updateValueInChargerValues('config.front_led.night_period_st', timeStringToSeconds(e.target.value))} />
                <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.front_led.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.front_led.night_period_sp', timeStringToSeconds(e.target.value))} />
              </>
            )}
            <WhiteSpace height="16px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.backLight')}</ModalDetailTitle>
              <WhiteSpace width='8px' />
              <ModalDetailTitleThin>{`(${t('charger.backpanel')})`}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.rear_led.enabled} setToggled={(value) => updateValueInChargerValues('config.rear_led.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="42px" />
            <Slider value={chargerValues.config.rear_led.brightness} setValue={(value) => updateValueInChargerValues('config.rear_led.brightness', value)} min={10} max={100} step={1} numberOfDots={5} width="100%" firstImg="settings.dim" lastImg="settings.bright" firstImgColor="gray_500" lastImgColor="gray_500" />
            <WhiteSpace height="32px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.color')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <ColorInput type="color"
                  value={rgbToHex(chargerValues.config.rear_led.color_r, chargerValues.config.rear_led.color_g, chargerValues.config.rear_led.color_b)}
                  onChange={(e) => {
                    const rgb = hexToRgb(e.target.value)
                    const newChargerValues: ChargerValuesType = structuredClone(chargerValues)
                    newChargerValues.config.rear_led.color_r = rgb.r
                    newChargerValues.config.rear_led.color_g = rgb.g
                    newChargerValues.config.rear_led.color_b = rgb.b
                    setChargerValues(newChargerValues)
                  }}
                />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.turnedOffPeriod')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.rear_led.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.rear_led.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.rear_led.night_mode_en && (
              <>
                <WhiteSpace height="18px" />
                <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                <WhiteSpace height="8px" />
                <TimeInput type="time" value={secondsToTimeString(chargerValues.config.rear_led.night_period_st)} onChange={(e) => updateValueInChargerValues('config.rear_led.night_period_st', timeStringToSeconds(e.target.value))} />
                <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.rear_led.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.rear_led.night_period_sp', timeStringToSeconds(e.target.value))} />
              </>
            )}
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSaveLightChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingLightSettings(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}





      {isShowingSpeakerSettings && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingSpeakerSettings(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingSpeakerSettings(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.speakerSettings')}</ModalTitle>
            <WhiteSpace height="34px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.speakerState')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.sound.enabled} setToggled={(value) => updateValueInChargerValues('config.sound.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.turnedOffPeriod')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.sound.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.sound.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.sound.night_mode_en && (
              <>
                <WhiteSpace height="18px" />
                <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                <WhiteSpace height="8px" />
                <TimeInput type="time" value={secondsToTimeString(chargerValues.config.sound.night_period_st)} onChange={(e) => updateValueInChargerValues('config.sound.night_period_st', timeStringToSeconds(e.target.value))} />
                <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.sound.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.sound.night_period_sp', timeStringToSeconds(e.target.value))} />
              </>
            )}
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSaveSpeakerChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingSpeakerSettings(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}





      {isShowingPowerOptions && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingPowerOptions(false)}>
          <ModalContainer style={{ position: 'relative', overflowY: 'scroll', overflowX: 'hidden' }}>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingPowerOptions(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.powerOptions')}</ModalTitle>
            <WhiteSpace height="34px" />


            <div style={{ display: 'flex' }}>
              <Icon path="chargers.average" width="24px" height="24px" marginleft="4px" marginright="4px" />
              <ModalDetailTitleThin>{`${t('charger.maxAmper')}:`}</ModalDetailTitleThin>
              <WhiteSpace width='8px' />
              <ModalDetailTitle>{`${chargerValues.config.power.curr_usr_limit} A`}</ModalDetailTitle>
              <div style={{ display: 'flex', position: 'absolute', right: '20px' }}>
                <ModalDetailTitleThin>{`${t('charger.maxPower')}:`}</ModalDetailTitleThin>
                <WhiteSpace width='8px' />
                <ModalDetailTitle>{`${chargerValues.config.power.curr_usr_limit * (230 * 3) / 1000} kW`}</ModalDetailTitle>
              </div>
            </div>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.power.curr_usr_limit} setValue={(value) => updateValueInChargerValues('config.power.curr_usr_limit', value)} min={chargerValues.config.power.curr_hw_limit_min} max={chargerValues.config.power.curr_hw_limit} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${chargerValues.config.power.curr_hw_limit})`} />
            <WhiteSpace height="32px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.scheduledPower')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.power.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.power.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.power.night_mode_en && (
              <>
                <WhiteSpace height='32px' />
                <div style={{ display: 'flex' }}>
                  <Icon path="chargers.average" width="24px" height="24px" marginleft="4px" marginright="4px" />
                  <ModalDetailTitleThin>{`${t('charger.maxAmper')}:`}</ModalDetailTitleThin>
                  <WhiteSpace width='8px' />
                  <ModalDetailTitle>{`${chargerValues.config.power.curr_night_limit} A`}</ModalDetailTitle>
                  <div style={{ display: 'flex', position: 'absolute', right: '20px' }}>
                    <ModalDetailTitleThin>{`${t('charger.maxPower')}:`}</ModalDetailTitleThin>
                    <WhiteSpace width='8px' />
                    <ModalDetailTitle>{`${chargerValues.config.power.curr_night_limit * (230 * 3) / 1000} kW`}</ModalDetailTitle>
                  </div>
                </div>
                <WhiteSpace height="18px" />
                <Slider value={chargerValues.config.power.curr_night_limit} setValue={(value) => updateValueInChargerValues('config.power.curr_night_limit', value)} min={chargerValues.config.power.curr_hw_limit_min} max={chargerValues.config.power.curr_hw_limit} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${chargerValues.config.power.curr_hw_limit})`} />
                <div>
                  <WhiteSpace height="48px" />
                  <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                  <WhiteSpace height="8px" />
                  <TimeInput type="time" value={secondsToTimeString(chargerValues.config.power.night_period_st)} onChange={(e) => updateValueInChargerValues('config.power.night_period_st', timeStringToSeconds(e.target.value))} />
                  <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.power.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.power.night_period_sp', timeStringToSeconds(e.target.value))} />
                </div>
              </>
            )}
            <WhiteSpace height="16px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.intelligentPower')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.power.dlm_mode != 0} setToggled={(value) => {
                  if (chargerValues.config.power.dlm_meter_state != 0 && chargerValues.config.power.dlm_meter_state != 1) {
                    updateValueInChargerValues('config.power.dlm_mode', value ? 1 : 0)
                  }
                }} />
              </div>
            </div>
            {(chargerValues.config.power.dlm_meter_state == 0 || chargerValues.config.power.dlm_meter_state == 1) && (
              <>
                <WhiteSpace height='16px' />
                <div style={{ display: 'flex' }}>
                  <Icon path="general.warning" width="24px" height="24px" marginleft="4px" marginright="4px" />
                  <WhiteSpace width='4px' />
                  <ModalDetailTitleThin>{t('charger.externalMeterIsNotAvailable')}</ModalDetailTitleThin>
                </div>
              </>
            )}
            {chargerValues.config.power.dlm_mode != 0 && (
              <>
                <div style={{ display: 'flex', marginTop: '18px', backgroundColor: '#E4E6EF', borderRadius: '12px', padding: '3px' }}>
                  <Button label={t('charger.same')} width="50%" bgcolor={chargerValues.config.power.dlm_dyn_specific ? "#E4E6EF" : "#F5F8FA"} labelcolor="#181C32" padding="8px" onclick={() => {
                    updateValueInChargerValues('config.power.dlm_dyn_specific', false)
                  }} />
                  <Button label={t('charger.specific')} width="50%" bgcolor={chargerValues.config.power.dlm_dyn_specific ? "#F5F8FA" : "#E4E6EF"} labelcolor="#181C32" padding="8px" onclick={() => {
                    updateValueInChargerValues('config.power.dlm_dyn_specific', true)
                  }} />
                </div>
                {!chargerValues.config.power.dlm_dyn_specific ? <>
                  <WhiteSpace height='32px' />
                  <div style={{ display: 'flex' }}>
                    <Icon path="chargers.average" width="24px" height="24px" marginleft="4px" marginright="4px" />
                    <ModalDetailTitleThin>{`${t('charger.maxAmper')}:`}</ModalDetailTitleThin>
                    <WhiteSpace width='8px' />
                    <ModalDetailTitle>{`${chargerValues.config.power.dlm_dyn_curr_lim} A`}</ModalDetailTitle>
                    <div style={{ display: 'flex', position: 'absolute', right: '20px' }}>
                      <ModalDetailTitleThin>{`${t('charger.maxPower')}:`}</ModalDetailTitleThin>
                      <WhiteSpace width='8px' />
                      <ModalDetailTitle>{`${chargerValues.config.power.dlm_dyn_curr_lim * (230 * 3) / 1000} kW`}</ModalDetailTitle>
                    </div>
                  </div>
                  <WhiteSpace height="18px" />
                  {/* Max amper limit is not based on curr_hw_limit as before, but set manually to 150 */}
                  <Slider value={chargerValues.config.power.dlm_dyn_curr_lim} setValue={(value) => updateValueInChargerValues('config.power.dlm_dyn_curr_lim', value)} min={chargerValues.config.power.curr_hw_limit_min} max={/*chargerValues.config.power.curr_hw_limit*/ 150} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${/*chargerValues.config.power.curr_hw_limit*/ 150})`} />
                </> : <>
                  <WhiteSpace height='32px' />
                  <div style={{ display: 'flex' }}>
                    <ModalDetailTitleThin>{`${t('charger.phase')} 1:`}</ModalDetailTitleThin>
                    <WhiteSpace width='8px' />
                    <ModalDetailTitle>{`${chargerValues.config.power.dlm_dyn_curr1_lim} A`}</ModalDetailTitle>
                  </div>
                  <WhiteSpace height="8px" />
                  <Slider value={chargerValues.config.power.dlm_dyn_curr1_lim} setValue={(value) => updateValueInChargerValues('config.power.dlm_dyn_curr1_lim', value)} min={chargerValues.config.power.curr_hw_limit_min} max={150} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${150})`} />

                  <WhiteSpace height="42px" />

                  <div style={{ display: 'flex' }}>
                    <ModalDetailTitleThin>{`${t('charger.phase')} 2:`}</ModalDetailTitleThin>
                    <WhiteSpace width='8px' />
                    <ModalDetailTitle>{`${chargerValues.config.power.dlm_dyn_curr2_lim} A`}</ModalDetailTitle>
                  </div>
                  <WhiteSpace height="8px" />
                  <Slider value={chargerValues.config.power.dlm_dyn_curr2_lim} setValue={(value) => updateValueInChargerValues('config.power.dlm_dyn_curr2_lim', value)} min={chargerValues.config.power.curr_hw_limit_min} max={150} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${150})`} />

                  <WhiteSpace height="42px" />

                  <div style={{ display: 'flex' }}>
                    <ModalDetailTitleThin>{`${t('charger.phase')} 3:`}</ModalDetailTitleThin>
                    <WhiteSpace width='8px' />
                    <ModalDetailTitle>{`${chargerValues.config.power.dlm_dyn_curr3_lim} A`}</ModalDetailTitle>
                  </div>
                  <WhiteSpace height="8px" />
                  <Slider value={chargerValues.config.power.dlm_dyn_curr3_lim} setValue={(value) => updateValueInChargerValues('config.power.dlm_dyn_curr3_lim', value)} min={chargerValues.config.power.curr_hw_limit_min} max={150} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${150})`} />
                </>}
              </>
            )}
            {chargerValues.config.power.dlm_mode != 0 && (
              <>
                <WhiteSpace height="16px" />

                <ModalDetailDivider $isColor />

                <WhiteSpace height="8px" />
                <div style={{ display: 'flex' }}>
                  <ModalDetailTitle>{t('charger.solarPower')}</ModalDetailTitle>
                  <div style={{ position: 'absolute', right: '20px' }}>
                    <Switch toggled={chargerValues.config.power.dlm_mode == 2 || chargerValues.config.power.dlm_mode == 3} setToggled={(value) => updateValueInChargerValues('config.power.dlm_mode', value ? 2 : 1)} />
                  </div>
                </div>
                <WhiteSpace height="32px" />
                {(chargerValues.config.power.dlm_mode == 2 || chargerValues.config.power.dlm_mode == 3) && (
                  <div style={{ display: 'flex' }}>
                    <Icon path="settings.solarEcoMode" color="none" style={chargerValues.config.power.dlm_mode == 2 ? { width: '41%', cursor: 'pointer', position: 'absolute', left: '32px' } : { width: '41%', filter: 'grayscale(1)', cursor: 'pointer', position: 'absolute', left: '32px' }} onClick={() => updateValueInChargerValues('config.power.dlm_mode', 2)} />
                    <Icon path="settings.solarGreenMode" color="none" style={chargerValues.config.power.dlm_mode == 3 ? { width: '41%', cursor: 'pointer', position: 'absolute', right: '32px' } : { width: '41%', filter: 'grayscale(1)', cursor: 'pointer', position: 'absolute', right: '32px' }} onClick={() => updateValueInChargerValues('config.power.dlm_mode', 3)} />
                  </div>
                )}
              </>
            )}
            <WhiteSpace height="175px" />
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSavePowerOptionChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingPowerOptions(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}





      {isShowingConnections && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingConnections(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingConnections(false)} />
            </ModalCloseButtonContainer>
            {isShowingConnections === true && (
              <ModalTitle>{t('charger.connections')}</ModalTitle>
            )}
            {typeof isShowingConnections == 'string' && (
              <div style={{ display: 'flex' }}>
                <Icon path="general.backArrow" width="22px" height="22px" marginright="8px" onClick={() => setIsShowingConnections(true)} />
                {isShowingConnections == 'wifi' && (
                  <ModalSubcategoryTitle>{t('charger.wifi')}</ModalSubcategoryTitle>
                )}
                {isShowingConnections == 'ethernet' && (
                  <ModalSubcategoryTitle>{t('charger.ethernet')}</ModalSubcategoryTitle>
                )}
                {isShowingConnections == 'gsm' && (
                  <ModalSubcategoryTitle>{t('charger.cellular')}</ModalSubcategoryTitle>
                )}
              </div>
            )}
            <WhiteSpace height="34px" />
            {isShowingConnections === true && (
              <>
                <SettingContainer $color="gray_300" onClick={() => setIsShowingConnections('wifi')}>
                  <SettingIconContainer $small>
                    <Icon path="settings.wifi" color="primary" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{`${t('charger.wifi')} ${t('charger.settings')}`}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                  {chargerValues.rsp_stat.wifi && (
                    <ConnectionActiveLabel>{t('charger.active')}</ConnectionActiveLabel>
                  )}
                </SettingContainer>
                <SettingContainer $color="gray_300" onClick={() => setIsShowingConnections('ethernet')}>
                  <SettingIconContainer $small>
                    <Icon path="settings.ethernet" color="primary" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{`${t('charger.ethernet')} ${t('charger.settings')}`}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                  {chargerValues.rsp_stat.ethernet && (
                    <ConnectionActiveLabel>{t('charger.active')}</ConnectionActiveLabel>
                  )}
                </SettingContainer>
                <SettingContainer $color="gray_300" onClick={() => setIsShowingConnections('gsm')}>
                  <SettingIconContainer $small>
                    <Icon path="settings.cellular" color="primary" width="100%" height="100%" />
                  </SettingIconContainer>
                  <SettingLabel>{`${t('charger.cellular')} ${t('charger.settings')}`}</SettingLabel>
                  <SettingArrow>
                    <Icon path="general.forwardArrow" width="100%" height="100%" />
                  </SettingArrow>
                  {chargerValues.rsp_stat.gsm && (
                    <ConnectionActiveLabel>{t('charger.active')}</ConnectionActiveLabel>
                  )}
                </SettingContainer>
              </>
            )}
            {isShowingConnections == 'wifi' && (
              <>
                {[
                  { label: 'wifiName', value: chargerValues.rsp_stat.wifi?.ssid || '-' },
                  { label: 'wifiSignalstrength', value: (chargerValues.rsp_stat.wifi?.quality || '-') + ' %' },
                  { label: 'rssi', value: (chargerValues.rsp_stat.wifi?.rssi || '-') + ' dBm' },
                  { label: 'ipAddress', value: chargerValues.rsp_stat.wifi?.ip || '-' },
                  { label: 'macAddress', value: chargerValues.rsp_stat.wifi?.mac || '-' },
                  { label: 'wifiSsidAccessPoint', value: chargerValues.rsp_stat.wifi?.ap_mode_ssid || '-' },
                  {
                    label: 'mode', value: (() => {
                      switch (chargerValues.rsp_stat.wifi?.mode) {
                        case 0: return t('charger.connectionsSettings.off')
                        case 1: return t('charger.connectionsSettings.accessPoint')
                        case 2: return t('charger.connectionsSettings.client')
                        default: '-'
                      }
                    })()
                  }
                ].map(row => (
                  <div style={{ display: 'flex', marginBottom: '24px' }}>
                    <ModalDetailTitle>{t(`charger.connectionsSettings.${row.label}`)}</ModalDetailTitle>
                    <ModalDetailTitleThin style={{ position: 'absolute', right: '24px' }}>{row.value}</ModalDetailTitleThin>
                  </div>
                ))}
              </>
            )}
            {isShowingConnections == 'ethernet' && (
              <>
                {[
                  { label: 'networkHostname', value: chargerValues.rsp_stat.ethernet?.hostname || '-' },
                  { label: 'ipAddress', value: chargerValues.rsp_stat.ethernet?.ip_address || '-' },
                  { label: 'netMask', value: chargerValues.rsp_stat.ethernet?.netmask || '-' },
                  { label: 'gateway', value: chargerValues.rsp_stat.ethernet?.gateway || '-' },
                  { label: 'dns', value: chargerValues.rsp_stat.ethernet?.dns || '-' },
                  {
                    label: 'mode', value: (() => {
                      switch (chargerValues.rsp_stat.ethernet?.mode) {
                        case 0: return t('charger.connectionsSettings.default')
                        case 1: return t('charger.connectionsSettings.dhcp')
                        case 2: return t('charger.connectionsSettings.staticIp')
                        default: '-'
                      }
                    })()
                  }
                ].map(row => (
                  <div style={{ display: 'flex', marginBottom: '24px' }}>
                    <ModalDetailTitle>{t(`charger.connectionsSettings.${row.label}`)}</ModalDetailTitle>
                    <ModalDetailTitleThin style={{ position: 'absolute', right: '24px' }}>{row.value}</ModalDetailTitleThin>
                  </div>
                ))}
              </>
            )}
            {isShowingConnections == 'gsm' && (
              <>
                {[
                  {
                    label: 'mode', value: (() => {
                      switch (chargerValues.rsp_stat.gsm?.mode) {
                        case 0: return t('charger.connectionsSettings.off')
                        case 1: return t('charger.connectionsSettings.backup')
                        case 2: return t('charger.connectionsSettings.primary')
                        default: '-'
                      }
                    })()
                  },
                  { label: 'status', value: chargerValues.rsp_stat.gsm?.status || '-' },
                  { label: 'imeiNumber', value: chargerValues.rsp_stat.gsm?.imei || '-' },
                  { label: 'iccidNumber', value: chargerValues.rsp_stat.gsm?.iccid || '-' },
                  { label: 'uptime', value: chargerValues.rsp_stat.gsm?.uptime || '-' },
                  { label: 'rssi', value: chargerValues.rsp_stat.gsm?.rssi || '-' },
                  { label: 'receivedBytes', value: chargerValues.rsp_stat.gsm?.rx_bytes || '-' },
                  { label: 'transferedBytes', value: chargerValues.rsp_stat.gsm?.tx_bytes || '-' },
                  { label: 'operator', value: chargerValues.rsp_stat.gsm?.operator || '-' }
                ].map(row => (
                  <div style={{ display: 'flex', marginBottom: '24px' }}>
                    <ModalDetailTitle>{t(`charger.connectionsSettings.${row.label}`)}</ModalDetailTitle>
                    <ModalDetailTitleThin style={{ position: 'absolute', right: '24px' }}>{row.value}</ModalDetailTitleThin>
                  </div>
                ))}
              </>
            )}
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={() => setIsShowingConnections(false)}>{t('charger.close')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}





      {isShowingMidMetering && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingMidMetering(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingMidMetering(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.midMetering')}</ModalTitle>
            <WhiteSpace height="34px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.midMeterEnabled')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.power.mid_meter_en} setToggled={(value) => updateValueInChargerValues('config.power.mid_meter_en', value)} />
              </div>
            </div>
            {chargerValues.config.power.mid_meter_en && (
              <>
                <WhiteSpace height="8px" />
                <ModalDetailDivider $isColor />
                <div style={{ display: 'flex' }}>
                  <ModalDetailTitle>{t('charger.midMeterState')}</ModalDetailTitle>
                  <ModalDetailTitleThin style={{ position: 'absolute', right: '24px' }}>{(() => {
                    switch (chargerValues.config.power.mid_meter_state) {
                      case 0: return t('charger.dlmMidStates.UNAVAILABLE_ERROR')
                      case 1: return t('charger.dlmMidStates.UNAVAILABLE')
                      case 2: return t('charger.dlmMidStates.CONNECTED_NOT_IN_USE')
                      case 3: return t('charger.dlmMidStates.CONNECTED_IN_USE')
                    }
                  })()}</ModalDetailTitleThin>
                </div>
                <WhiteSpace height="18px" />
                <div style={{ display: 'flex' }}>
                  <ModalDetailTitle>{t('charger.midMeterType')}</ModalDetailTitle>
                  <div style={{ position: 'absolute', right: '50px', width: '52%' }}>
                    <Select width={"100%"} options={[chargerValues.config.power.mid_meter_type == 3 ? "Carlo Gavazzi EMxxx w/ Modbus" : chargerValues.config.power.mid_meter_type == 5 ? "Inepro Pro380" : chargerValues.config.power.mid_meter_type == 7 ? "ORNO OR-WE-516" : "-", "Carlo Gavazzi EMxxx w/ Modbus", "Inepro Pro380", "ORNO OR-WE-516"]} handleSelect={(value) => { updateValueInChargerValues('config.power.mid_meter_type', value[0] == "C" ? 3 : value[0] == "I" ? 5 : value[0] == "O" ? 7 : 0) }} />
                  </div>
                </div>
              </>
            )}
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSaveMidMeteringChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingMidMetering(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}








      {isShowingIpMeterSettings && chargerValues && (
        <Modal align="right" onEscape={() => setIsShowingIpMeterSettings(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingIpMeterSettings(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.ipMeterSettings')}</ModalTitle>
            <WhiteSpace height="34px" />
            <ModalDetailTitle>{"Base Settings"}</ModalDetailTitle>
            <WhiteSpace height="8px" />
            <InputWithLabel label={"IP Address"} width={"100%"} value={chargerValues.config.ip_485.ip} onInput={(value) => updateValueInChargerValues('config.ip_485.ip', value)} />
            <WhiteSpace height="8px" />
            <InputWithLabel label={"Port"} width={"100%"} value={chargerValues.config.ip_485.port.toString()} onInput={(value) => updateValueInChargerValues('config.ip_485.port', value)} />
            <ModalDetailDivider $isColor />

            <ModalDetailTitle>{"Meter 1"}</ModalDetailTitle>
            <WhiteSpace height="18px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitleThin>{"Meter Type"}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '50px', width: '52%' }}>
                <Select width={"100%"} options={[["Disabled", "Carlo Gavazzi EMxxx", "Smart EVSE SensorBox", "Carlo Gavazzi EMxxx (MID)", "Inepro Pro380", "Inepro Pro380 (MID)", "ORNO OR-WE-516", "ORNO OR-WE-516 (MID)", "Voltie Sensor", "Huawei DTSU666"][chargerValues.config.ip_485.meter1.type], "Disabled", "Carlo Gavazzi EMxxx", "Smart EVSE SensorBox", "Carlo Gavazzi EMxxx (MID)", "Inepro Pro380", "Inepro Pro380 (MID)", "ORNO OR-WE-516", "ORNO OR-WE-516 (MID)", "Voltie Sensor", "Huawei DTSU666"]} handleSelect={(value) => { updateValueInChargerValues('config.ip_485.meter1.type', ["Disabled", "Carlo Gavazzi EMxxx", "Smart EVSE SensorBox", "Carlo Gavazzi EMxxx (MID)", "Inepro Pro380", "Inepro Pro380 (MID)", "ORNO OR-WE-516", "ORNO OR-WE-516 (MID)", "Voltie Sensor", "Huawei DTSU666"].indexOf(value)) }} />
              </div>
            </div>
            <WhiteSpace height="18px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitleThin>{"Usage Type"}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '50px', width: '52%' }}>
                <Select width={"100%"} options={[["None", "PS Usage", "OCPP Usage", "MID Usage", "EVSE Usage"][chargerValues.config.ip_485.meter1.usage], "None", "PS Usage", "OCPP Usage", "MID Usage", "EVSE Usage"]} handleSelect={(value) => { updateValueInChargerValues('config.ip_485.meter1.usage', ["None", "PS Usage", "OCPP Usage", "MID Usage", "EVSE Usage"].indexOf(value)) }} />
              </div>
            </div>
            <WhiteSpace height="18px" />
            <InputWithLabel label={"Serial Number"} width={"100%"} value={chargerValues.config.ip_485.meter1.sn} onInput={(value) => updateValueInChargerValues('config.ip_485.meter1.sn', value)} />

            <ModalDetailDivider $isColor />

            <ModalDetailTitle>{"Meter 2"}</ModalDetailTitle>
            <WhiteSpace height="18px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitleThin>{"Meter Type"}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '50px', width: '52%' }}>
                <Select width={"100%"} options={[["Disabled", "Carlo Gavazzi EMxxx", "Smart EVSE SensorBox", "Carlo Gavazzi EMxxx (MID)", "Inepro Pro380", "Inepro Pro380 (MID)", "ORNO OR-WE-516", "ORNO OR-WE-516 (MID)", "Voltie Sensor", "Huawei DTSU666"][chargerValues.config.ip_485.meter2.type], "Disabled", "Carlo Gavazzi EMxxx", "Smart EVSE SensorBox", "Carlo Gavazzi EMxxx (MID)", "Inepro Pro380", "Inepro Pro380 (MID)", "ORNO OR-WE-516", "ORNO OR-WE-516 (MID)", "Voltie Sensor", "Huawei DTSU666"]} handleSelect={(value) => { updateValueInChargerValues('config.ip_485.meter2.type', ["Disabled", "Carlo Gavazzi EMxxx", "Smart EVSE SensorBox", "Carlo Gavazzi EMxxx (MID)", "Inepro Pro380", "Inepro Pro380 (MID)", "ORNO OR-WE-516", "ORNO OR-WE-516 (MID)", "Voltie Sensor", "Huawei DTSU666"].indexOf(value)) }} />
              </div>
            </div>
            <WhiteSpace height="18px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitleThin>{"Usage Type"}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '50px', width: '52%' }}>
                <Select width={"100%"} options={[["None", "PS Usage", "OCPP Usage", "MID Usage", "EVSE Usage"][chargerValues.config.ip_485.meter2.usage], "None", "PS Usage", "OCPP Usage", "MID Usage", "EVSE Usage"]} handleSelect={(value) => { updateValueInChargerValues('config.ip_485.meter2.usage', ["None", "PS Usage", "OCPP Usage", "MID Usage", "EVSE Usage"].indexOf(value)) }} />
              </div>
            </div>
            <WhiteSpace height="18px" />
            <InputWithLabel label={"Serial Number"} width={"100%"} value={chargerValues.config.ip_485.meter2.sn} onInput={(value) => updateValueInChargerValues('config.ip_485.meter2.sn', value)} />


          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSaveIpMeterSettingsChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingIpMeterSettings(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}






      {isShowingDebugLevels && (
        <Modal align="right" onEscape={() => { setIsShowingDebugLevels(false); setDebugLevels(null) }}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => { setIsShowingDebugLevels(false); setDebugLevels(null) }} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.debugLevels')}</ModalTitle>
            <WhiteSpace height="8px" />

            {(debugLevels && debugLevels.length > 0) ? <>
              <Table id="Settings_DebugLevels" height="calc(85vh - 90px)" columns={[
                { width: '5%' },
                { label: 'Name', width: '45%' },
                { label: 'Level', width: '50%' }
              ]} data={debugLevels.map((debugLevel, index) => [
                {
                  custom: <></>
                }, {
                  value: {
                    type: TableCellTypes.Label,
                    value: debugLevel.name
                  }
                }, {
                  custom: <Select width='80%' options={[['1 - low', '2 - medium', '3 - high', '4 - very high'][debugLevel.value - 1], ...['1 - low', '2 - medium', '3 - high', '4 - very high']]} handleSelect={(value) => handleUpdateDebugLevel(index, parseInt(value[0]))} isAbsolute={true} />
                }
              ])} />
            </> : <p style={{ width: '100%', textAlign: 'center', fontWeight: '700' }}><LoaderDots /></p>}

          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={() => { setIsShowingDebugLevels(false); setDebugLevels(null) }}>{t('charger.close')}</ModalButton>
            <ModalButton onClick={() => { setDebugLevels(null); setTimeout(fetchCMD_GET_DEBUG_LEVEL, 300) }} $hasBg>{t('charger.refresh')}</ModalButton>
            <ModalButton onClick={() => { setDebugLevels(null); setTimeout(handleResetDebugLevelsToDefault, 300) }} $hasBg>{t('charger.reset')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}
    </>
  )
}