import { useTranslation } from "react-i18next"
import PageTitle from "../../components/PageTitle"
import WhiteSpace from "../../components/Whitespace"
import Comm from "../../Comm"
import { useEffect, useState } from "react"
import ChargeType from "../../types/ChargeType"
import ChargerType from "../../types/ChargerType"
import styled from "styled-components"
import Dropdown from "../../components/Dropdown"
import LoaderDots from "../../components/LoaderDots"
import Modal from "../../components/Modal"
import React from "react"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Icon from "../../Icon"
import secondsToHumanReadable from "../../utils/secondsToHumanReadable"
import { getAuth } from "firebase/auth"
import fbConf from "../../FirebaseConf"
import Notification from "../../components/Notification"
import DateRangePicker from "../../components/DateRangePicker"
import { useOutletContext } from "react-router-dom"
import ExportButton from "../../modules/ExportButton"

const ToolbarContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 12px;
  display: flex;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const StatCardTable = styled.table`
  width: 100%;
`

const StatCard = styled.div<{ $color: string }>`
  border-radius: 24px;
  padding: 24px;
  background-color: ${props => props.theme.colors[props.$color]};
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
`

const StatCardIconContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 24px;
  height: 24px;
  padding: 7px;
  border-radius: 100px;
  margin-top: 5px;
`

const StatCardTitle = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-left: 18px;
`

const StatCardValue = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-left: 18px;
  margin-top: 6px;
`

const StatCardUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  padding-left: 6px;
`

const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
  width: 20vw;
`

const ModalContainer = styled.div`
  padding: 24px;
  height: 85vh;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const ChargeDetailContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const ChargeDetailLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
  margin-left: 8px;
`

const ChargeDetailValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  right: 0px;
  margin: 0px;
  margin-top: 4px;
`

const ChargeDetailsPeriodContainer = styled.div`
  box-shadow: 0px 0px 10px 0px #0000001A;
  border-radius: 16px;
  padding: 20px;
  width: calc(20vw - 40px);
`

const ChargeDetailsPeriodTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ChargeDetailsPeriodTimeLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ChargeDetailsPeriodsContainer = styled.div`
  overflow-y: scroll;
  max-height: calc(85vh - 510px);
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
`

const EmptyTableLabel = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.gray_900};
  margin-top: 48px;
  position: absolute;
`

const SearchbarLevelToolbarContainer = styled.div`
  position: absolute;
  margin-left: 15vw;
  margin-top: 8px;
  z-index: 1;
  display: flex;
`

export default function Charges({ charger, isAdmin }: { charger: ChargerType, isAdmin: boolean }) {
  const { t } = useTranslation()
  const { theme } = useOutletContext<{ theme: string }>()

  const [selectedDateRange, setSelectedDateRange] = useState<Date[]>()

  const [charges, setCharges] = useState<ChargeType[] | null>()
  const [chargesInSelectedDateRange, setChargesInSelectedDateRange] = useState<ChargeType[] | null>()
  const [lastRefresh, setLastRefresh] = useState<string>('')

  const [cdrStats, setCdrStats] = useState<{ lifetimeChgSec: number, lifetimeWh: number }>()
  const [allIdleTime, setAllIdleTime] = useState<number>()

  const [isShowingChargeDetails, setIsShowingChargeDetails] = useState<ChargeType | null>()
  const [isShowingExportChargeExportModalForAdmins, setIsShowingExportChargeExportModalForAdmins] = useState<ChargeType | null>()
  const [notification, setNotification] = useState<string>('')


  async function fetchCharges() {
    const response = await Comm('/api-cdr/cdrs/last', {
      headers: { "Charger_ID": charger.chargerId }
    })
    const result = await response.json()
    result.sort((a: ChargeType, b: ChargeType) => b.cdr_id - a.cdr_id)
    //result.reverse()
    setCharges(result)
    // All charges are in the date range by default
    setChargesInSelectedDateRange(result)
    setSelectedDateRange([new Date(result[result.length - 1].s_start * 1000), new Date(result[0].s_end * 1000)])
    setLastRefresh(new Date().toString().split(' ')[4])


    // Calculate stats
    let idleTime = 0
    for (const charge of result) {
      idleTime += charge.idle_time / 1000
    }
    setAllIdleTime(idleTime)

    // Fetch CDR stats if charger is online for stats in the top row (wait two seconds to make sure that charger is ready to respond)
    setTimeout(() => {
      if (charger.online) {
        fetchCdrStats()
      }
    }, 2000)
  }
  useEffect(() => {
    fetchCharges()
  }, [])

  function handelRefresh() {
    setCharges(null)
    setChargesInSelectedDateRange(null)
    fetchCharges()
  }

  // Email export, only for Users
  async function handleExportChargeForUsers(charge: ChargeType) {
    const userId = getAuth(fbConf).currentUser?.uid
    if (userId) {
      setNotification(t('charger.sendingViaEmail'))
      const response = await Comm(`/api-financial/report/excel/${charge.cdr_id}`, {
        headers: { "user_id": userId, "Content-Type": "application/json" }
      })
      if (response.ok) {
        setNotification(t('charger.theExportedExcelFileHasBeenSentViaEmail'))
      } else {
        setNotification(`Error while sending email`)
      }
    }
  }

  // Email export, only for Users
  async function handleExportChargesForUsers() {
    const userId = getAuth(fbConf).currentUser?.uid
    if (userId && charges && selectedDateRange) {
      setNotification(t('charger.sendingViaEmail'))
      const response = await Comm(`/api-financial/report/excel?from=${Math.round((selectedDateRange[0].getTime() - 86400000) / 1000)}&to=${Math.round(selectedDateRange[1].getTime() / 1000) - 1}`, {
        headers: { "user_id": userId, "Content-Type": "application/json" }
      })
      if (response.ok) {
        setNotification(t('charger.theExportedExcelFileHasBeenSentViaEmail'))
      } else {
        setNotification(`Error while sending email`)
      }
    }
  }

  async function fetchCdrStats() {
    const CMD_GET_PRODUCT_NFO_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 12 })
    })
    const CMD_GET_PRODUCT_NFO_result = await CMD_GET_PRODUCT_NFO_response.json()

    setCdrStats({
      lifetimeChgSec: CMD_GET_PRODUCT_NFO_result.lifetime?.lifetime_chg_sec || 0,
      lifetimeWh: CMD_GET_PRODUCT_NFO_result.lifetime?.lifetime_Wh
    })
  }

  function handleChooseDateRange(range: Date[]) {
    if (!range[1]) {
      range[1] = new Date(range[0].getTime() + 86400000)
    }

    if (charges) {
      setSelectedDateRange(range)
      const chargesInRange = []
      const rangeStartUnix = Math.round((range[0].getTime() - 86400000) / 1000)
      const rangeEndUnix = Math.round(range[1].getTime() / 1000)
      for (const charge of charges) {
        if (charge.s_start > rangeStartUnix && charge.s_end < rangeEndUnix) {
          chargesInRange.push(charge)
        }
      }
      setChargesInSelectedDateRange(chargesInRange)
    }
  }

  return (
    <>
      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.charges')} />
      {isShowingExportChargeExportModalForAdmins && <>
        <ExportButton fileName={`CDR ${isShowingExportChargeExportModalForAdmins.cdr_id}`} handleAfterDone={() => setIsShowingExportChargeExportModalForAdmins(null)} modalOnly titleRow={[
          { label: 'CDR ID', width: '10' },
          { label: 'Start', width: '20' },
          { label: 'Stop', width: '20' },
          { label: 'All Energy', width: '15' },
          { label: 'Charge Time', width: '15' },
          { label: 'Idle Time', width: '15' },
          { label: 'Average Power', width: '15' },
          { label: 'RFID Name', width: '25' },
          { label: 'Charger ID', width: '20' },
          { label: 'Number of Periods', width: '20' },
          { label: 'Periods', width: '150' }
        ]} data={[isShowingExportChargeExportModalForAdmins].map(cdr => [cdr.cdr_id, new Date(cdr.s_start * 1000).toLocaleString('hu'), new Date(cdr.s_end * 1000).toLocaleString('hu'), `${+cdr.chg_energy.toFixed(2)} kWh`, secondsToHumanReadable(cdr.chg_time).replace(/\n/g, '').replace(/ /g, '').replace(/h/g, 'h '), secondsToHumanReadable(cdr.idle_time).replace(/\n/g, '').replace(/ /g, '').replace(/h/g, 'h '), `${+cdr.avg_power.toFixed(2)} kWh`, cdr.idtag_name, cdr.charger_id, cdr.periods.length, JSON.stringify(cdr.periods).replace(/\},{/g, '},\n{')])} />
      </>}
      {notification && (
        <Notification setState={setNotification} label={notification} />
      )}
      {isShowingChargeDetails && (
        <Modal align="right" onEscape={() => setIsShowingChargeDetails(null)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingChargeDetails(null)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.chargeDetails')}</ModalTitle>
            <WhiteSpace height="34px" />
            {[
              { icon: "chargers.rfidTag", label: t('charger.rfid'), value: isShowingChargeDetails.idtag_name || '-' },
              { icon: "chargers.rfidTag", label: t('general.number'), value: isShowingChargeDetails.idtag || '-' },
              { icon: "chargers.pluggedIn", label: t('charger.pluggedIn'), value: new Date(isShowingChargeDetails.s_start * 1000).toLocaleTimeString('hu') },
              { icon: "chargers.unplugged", label: t('charger.unplugged'), value: new Date(isShowingChargeDetails.s_end * 1000).toLocaleTimeString('hu') },
              { icon: "chargers.time", iconColor: 'primary', label: t('charger.chargingTime'), value: secondsToHumanReadable(isShowingChargeDetails.chg_time) },
              { icon: "chargers.sleep", iconColor: 'gray_500', label: t('charger.idleTime'), value: secondsToHumanReadable(isShowingChargeDetails.idle_time) },
              { icon: "chargers.energy", iconColor: 'warning', label: t('charger.energy'), value: `${isShowingChargeDetails.chg_energy} kWh` },
              { icon: "chargers.average", label: t('charger.averagePower'), value: `${isShowingChargeDetails.avg_power} kW` },
              { adminOnly: true, icon: "chargers.humidity", iconColor: 'primary', label: `${t('charger.humidity')} (max/min)`, value: `${isShowingChargeDetails.hum_max}% / ${isShowingChargeDetails.hum_min}%` },
              { adminOnly: true, icon: "chargers.thermometer", iconColor: 'danger', label: `PI (max/${t('charger.avg')})`, value: `${isShowingChargeDetails.temp_pi_max} °C / ${isShowingChargeDetails.temp_pi_avg} °C` },
              { adminOnly: true, icon: "chargers.thermometer", iconColor: 'danger', label: `POW (max/${t('charger.avg')})`, value: `${isShowingChargeDetails.temp_pow_max} °C / ${isShowingChargeDetails.temp_pow_avg} °C` },
              { adminOnly: true, icon: "chargers.thermometer", iconColor: 'danger', label: `UC (max/${t('charger.avg')})`, value: `${isShowingChargeDetails.temp_uc_max} °C / ${isShowingChargeDetails.temp_uc_avg} °C` }
            ].map(dataPoint => (dataPoint.adminOnly && isAdmin) || (!dataPoint.adminOnly) ? (
              <React.Fragment key={dataPoint.label}>
                <ChargeDetailContainer>
                  <Icon path={dataPoint.icon} color={dataPoint.iconColor} width="24px" height="24px" />
                  <ChargeDetailLabel>{dataPoint.label}</ChargeDetailLabel>
                  <ChargeDetailValue>{dataPoint.value}</ChargeDetailValue>
                </ChargeDetailContainer>
                <WhiteSpace height="16px" />
              </React.Fragment>
            ) : null)}
            <WhiteSpace height="16px" />
            <ChargeDetailsPeriodsContainer>
              {isShowingChargeDetails.periods.map(period => (
                <React.Fragment key={`period${period.index}`}>
                  <ChargeDetailsPeriodContainer>
                    <ChargeDetailsPeriodTitle>{`${t('charger.period')} ${period.index + 1}`}</ChargeDetailsPeriodTitle>
                    <ChargeDetailsPeriodTimeLabel>{`${new Date(period.p_start * 1000).toISOString().split('T')[1].split('.')[0]} - ${new Date(period.p_end * 1000).toISOString().split('T')[1].split('.')[0]}`}</ChargeDetailsPeriodTimeLabel>
                    <WhiteSpace height="16px" />
                    <ChargeDetailContainer>
                      <Icon path="chargers.energy" color="warning" width="24px" height="24px" />
                      <ChargeDetailLabel>{t('charger.energy')}</ChargeDetailLabel>
                      <ChargeDetailValue>{`${period.p_energy} kWh`}</ChargeDetailValue>
                    </ChargeDetailContainer>
                  </ChargeDetailsPeriodContainer>
                  <WhiteSpace height="16px" />
                </React.Fragment>
              ))}
            </ChargeDetailsPeriodsContainer>
          </ModalContainer>
        </Modal>
      )}
      <StatCardTable>
        <tbody>
          <tr>
            <td style={{ width: '20%' }}>
              <StatCard $color="dark">
                <StatCardIconContainer>
                  <Icon path="chargers.power" width="100%" height="100%" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle $white>{t('charger.allCharges')}</StatCardTitle>
                  <StatCardValue $white>{charges ? charges.length : <LoaderDots />}<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="warning">
                <StatCardIconContainer>
                  <Icon path="chargers.energy" width="100%" height="100%" color="warning" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle>{t('charger.allChargedEnergy')}</StatCardTitle>
                  <StatCardValue>{charger.online ? cdrStats ? (cdrStats.lifetimeWh / 1000).toFixed(2) : <LoaderDots /> : '-'}<StatCardUnit>kWh</StatCardUnit></StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="primary">
                <StatCardIconContainer>
                  <Icon path="chargers.time" width="100%" height="100%" color="primary" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle>{t('charger.allChargetime')}</StatCardTitle>
                  <StatCardValue>{charger.online ? cdrStats ? secondsToHumanReadable(cdrStats.lifetimeChgSec) : <LoaderDots /> : '-h'}</StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="dark">
                <StatCardIconContainer>
                  <Icon path="chargers.average" width="100%" height="100%" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle $white>{t('charger.averageChargedEnergy')}</StatCardTitle>
                  <StatCardValue $white>{charger.online ? cdrStats && charges ? (((cdrStats.lifetimeWh / 1000) / charges.length) || 0).toFixed(2) : <LoaderDots /> : '-'}<StatCardUnit>kWh</StatCardUnit></StatCardValue>
                </div>
              </StatCard>
            </td>
            <td style={{ width: '20%' }}>
              <StatCard $color="gray_300">
                <StatCardIconContainer>
                  <Icon path="chargers.sleep" width="100%" height="100%" color="gray_600" />
                </StatCardIconContainer>
                <div>
                  <StatCardTitle>{t('charger.allIdleTime')}</StatCardTitle>
                  <StatCardValue>{charger.online ? typeof allIdleTime === 'number' ? secondsToHumanReadable(allIdleTime) : <LoaderDots /> : '-'}</StatCardValue>
                </div>
              </StatCard>
            </td>
          </tr>
        </tbody>
      </StatCardTable>
      <ToolbarContainer>
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('charger.refresh')} onClick={handelRefresh} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastRefresh}</RefreshTimerLabel>
        </div>
      </ToolbarContainer>
      {selectedDateRange && <>
        <SearchbarLevelToolbarContainer style={!charges ? { visibility: 'hidden' } : {}}>
          <div>
            <DateRangePicker theme={theme} onSelect={(range) => handleChooseDateRange(range)} defaultDate={selectedDateRange} />
          </div>
          <WhiteSpace width="32px" />
          <div style={{ marginTop: "6px" }}>
            {isAdmin ? <>
              {chargesInSelectedDateRange && <>
                <ExportButton fileName="CDRs" titleRow={[
                  { label: 'CDR ID', width: '10' },
                  { label: 'Start', width: '20' },
                  { label: 'Stop', width: '20' },
                  { label: 'All Energy', width: '15' },
                  { label: 'Charge Time', width: '15' },
                  { label: 'Idle Time', width: '15' },
                  { label: 'Average Power', width: '15' },
                  { label: 'RFID Name', width: '25' },
                  { label: 'Charger ID', width: '20' },
                  { label: 'Number of Periods', width: '20' },
                  { label: 'Periods', width: '150' }
                ]} data={chargesInSelectedDateRange.map(cdr => [cdr.cdr_id, new Date(cdr.s_start * 1000).toLocaleString('hu'), new Date(cdr.s_end * 1000).toLocaleString('hu'), `${+cdr.chg_energy.toFixed(2)} kWh`, secondsToHumanReadable(cdr.chg_time).replace(/\n/g, '').replace(/ /g, '').replace(/h/g, 'h '), secondsToHumanReadable(cdr.idle_time).replace(/\n/g, '').replace(/ /g, '').replace(/h/g, 'h '), `${+cdr.avg_power.toFixed(2)} kWh`, cdr.idtag_name, cdr.charger_id, cdr.periods.length, JSON.stringify(cdr.periods).replace(/\},{/g, '},\n{')])} />
              </>}

            </> : <>
              <ButtonWithIcon icon="general.export" label={t('chargerLogs.export')} onClick={handleExportChargesForUsers} backgroundColor="white" />
            </>}
          </div>
        </SearchbarLevelToolbarContainer>
        <WhiteSpace width="8px" />
      </>}
      <WhiteSpace height="8px" />
      {(chargesInSelectedDateRange && chargesInSelectedDateRange.length > 0) ? (
        <Table id="Charges" height="calc(90vh - 290px)" isSearchEnabled={true} isPaginationEnabled={true} triggerRefresh={chargesInSelectedDateRange.toString().length} columns={[
          { width: '30px' },
          { label: t('charger.chargeId'), width: '8%' },
          { label: t('charger.chargingStart'), width: '10%' },
          { label: t('charger.chargingEnd'), width: '10%' },
          { label: t('charger.rfid'), width: '23%' },
          { label: t('charger.chargedEnergy'), width: '11%' },
          { label: t('charger.chargingTime'), width: '11%' },
          { label: t('charger.averagePower'), width: '11%' },
          { label: t('charger.numberOfChargingPeriods') },
          { width: '20px' }
        ]} data={chargesInSelectedDateRange.map(charge => [
          {
            onClick: () => setIsShowingChargeDetails(charge),
            value: {
              type: TableCellTypes.Image,
              src: (() => {
                switch (charge.periods && charge.periods[0] && charge.periods[0].p_stop_ev ? charge.periods[0].p_stmode : false) {
                  case 2: return "chargers.scheduledCharging"
                  case 3: return "chargers.kWhBasedCharging"
                  default: return "chargers.normalCharging"
                }
              })()
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.cdr_id,
            value: {
              type: TableCellTypes.Label,
              value: charge.cdr_id.toString()
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.s_start,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: new Date(charge.s_start * 1000).toLocaleDateString('hu'),
              value2: new Date(charge.s_start * 1000).toLocaleTimeString('hu')
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.s_end,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: new Date(charge.s_end * 1000).toLocaleDateString('hu'),
              value2: new Date(charge.s_end * 1000).toLocaleTimeString('hu')
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: `${charge.idtag_name} ${charge.idtag}` || '-',
            value: {
              type: TableCellTypes.LabelWithIcon,
              value: charge.idtag_name || '-',
              src: "chargers.rfidTag"
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.chg_energy,
            value: {
              type: TableCellTypes.LabelWithIcon,
              value: `${+charge.chg_energy.toFixed(2)} kWh`,
              src: "chargers.energy",
              iconColor: "warning"
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.chg_time,
            value: {
              type: TableCellTypes.LabelWithIcon,
              value: secondsToHumanReadable(charge.chg_time),
              src: "chargers.time",
              iconColor: "primary"
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.avg_power,
            value: {
              type: TableCellTypes.LabelWithIcon,
              value: `${+charge.avg_power.toFixed(2)} kW`,
              src: "chargers.average"
            }
          }, {
            onClick: () => setIsShowingChargeDetails(charge),
            sortBy: charge.periods ? charge.periods.length : 0,
            value: {
              type: TableCellTypes.Label,
              value: charge.periods ? charge.periods.length.toString() : '0'
            }
          }, {
            custom: <Dropdown handleChoose={(value) => value == t('general.export') ? (isAdmin ? setIsShowingExportChargeExportModalForAdmins(charge) : handleExportChargeForUsers(charge)) : setIsShowingChargeDetails(charge)} title={{ icon: "general.more", label: '' }} options={[{ icon: "general.log", label: t('charger.details') }, { icon: "general.export", label: t('general.export') }]} showOptionContainerOnLeft />
          }
        ])} />
      ) : charges && charges.length == 0 ? <EmptyTableLabel>{t(`charger.noCharges`)}</EmptyTableLabel> : <EmptyTableLabel><LoaderDots /></EmptyTableLabel>}
    </>
  )
}