import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import ChargerType from "../../types/ChargerType"
import styled from "styled-components"
import { useEffect, useState } from "react"
import ChargeType from "../../types/ChargeType"
import Comm from "../../Comm"
import LoaderDots from "../../components/LoaderDots"
import Navigator from "../../components/Navigator"
import SlideToConfirm from "../../components/SlideToConfirm"
import dateToTimeAgoString from "../../utils/dateToTimeAgoString"
import { child, get, getDatabase, ref, set } from "firebase/database"
import fbConf from "../../FirebaseConf"
import { getAuth } from "firebase/auth"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Modal from "../../components/Modal"
import Button from "../../components/Button"
import Icon from "../../Icon"
import React from "react"
import secondsToHumanReadable from "../../utils/secondsToHumanReadable"
import Switch from "../../components/Switch"
import InputWithLabel from "../../components/InputWithLabel"
import Select from "../../components/Select"

interface EvseStateType {
  sys_time: number,
  evse_state: number,
  phase: 1 | 3,
  u_eff: number,
  chg_on: boolean,
  chg_current: number,
  chg_power: number,
  srs: number,
  chg_enabled: boolean,
  autostart: number,
  temp_uc: number,
  temp_pow: number,
  temp_pi: number,
  hum: number,
  first_cdr: number,
  last_cdr: number,
  max_current: number,
}

const GeneralContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
  width: 62%;
`

const StatusContainer = styled.div<{ $bgColorTop: string, $bgColorBottom: string }>`
  position: relative;
  width: 12.5vw;
  height: 12.5vw;
  background-image: ${props => `linear-gradient(${props.$bgColorTop}, ${props.$bgColorBottom})`};
  border-radius: 16px;
  user-select: none;
  -webkit-user-select: none;
`

const StatusTitle = styled.span<{ $bgColor: string, $fontColor: string }>`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.$fontColor};
  background-color: ${props => props.$bgColor};
  border: 1px solid white;
  border-radius: 40px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  transform: translate(-50%, -50%);
  left: 50%;
`

const StatusOfflineLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #718096;
  width: 100%;
  margin: 0px;
  padding-top: 20px;
  text-align: center;
`

const StatusLabel = styled.p<{ $color: string }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$color};
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
`

const ChargerNameLabel = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  left: 15vw;
  top: 0px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const ChargerNameValue = styled.input<{ $width: string }>`
  font-weight: 700;
  font-size: 28px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  padding-right: 30px;
  left: 15vw;
  top: 44px;
  width: ${props => props.$width};
  border: none;
  outline: none;
`

const UserIdEmailLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  position: absolute;
  left: 35vw;
  top: 8px;
`

const UserIdEmailValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  left: 35vw;
  top: 26px;
`

const GeneralDetailsTabel = styled.table`
  position: absolute;
  left: calc(12.5vw + 18px + 18px);
  bottom: 75px;
  width: calc(100% - 12.5vw - 18px - 18px);
`

const GeneralDetailsDivider = styled.div`
  position: absolute;
  left: calc(12.5vw + 18px + 18px);
  bottom: 65px;
  width: calc(100% - 12.5vw - 18px - 18px - 18px);
  height: 2px;
  background-image: linear-gradient(270deg, #E2594B 0%, #2A6FF4 100%);
`

const GeneralDetailLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
`

const GeneralDetailValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ConnectionContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
`

const ConnectionTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
`

const ConnectionTableCell = styled.td`
  display: flex;
  width: 33.3%;
  margin-top: 12px;
`

const ConnectionLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
  width: 10vw;
`

const ConnectionValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: -1px;
`

const ConnectionTableDivider = styled.p<{ $left: string }>`
  position: absolute;
  height: 134px;
  width: 0px;
  border: 1px solid ${props => props.theme.colors.gray_200};
  left: ${props => props.$left};
  top: 60px;
`

const StatCardTable = styled.table`
  width: 35%;
  position: absolute;
  right: 0px;
`

const StatCard = styled.div<{ $color: string }>`
  border-radius: 24px;
  padding: 24px;
  background-color: ${props => props.theme.colors[props.$color]};
  display: flex;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  height: calc(((12.5vw - 24px - 36px) / 2) - 10px);
`

const StatCardIconContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 18px;
  height: 18px;
  padding: 7px;
  border-radius: 100px;
  position: absolute;
  right: 24px;
  top: 24px;
`

const StatCardTitle = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
`

const StatCardValue = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-top: calc(((12.5vw - 24px - 36px) / 2) - 10px - 36px);
`

const StatCardUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  padding-left: 6px;
`

const CommentContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
`

const CommentTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
`

const ModalContainer = styled.div`
  padding: 24px;
  height: 85vh;
`

const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
  width: 20vw;
`

const SmallModalContainer = styled.div`
  padding: 24px;
`

const SmallModalLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

const SmallModalButtonContainer = styled.div`
  width: 15vw;
  display: flex;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const AddCommentTextArea = styled.textarea`
  width: calc(100% - 4px);
  height: 120px;
  border-radius: 4px;
  border: none;
  outline: none;
`

const CommentMessageText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  max-width: 100%;
  margin: 0px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`

const CommentAuthorText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const CommentDateText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_400};
  margin: 0px;
`

const CurrentChargeLabel = styled.p`
  color: ${props => props.theme.dark};
  font-size: 24px;
  font-weight: 700;
`

const CurrentChargeElaplesTimeLabel = styled.p`
  color: ${props => props.theme.dark};
  font-size: 14px;
  font-weight: 400;
`

const CurrentChargePeriodContainer = styled.div`
  box-shadow: 0px 0px 10px 0px #0000001A;
  border-radius: 16px;
  padding: 20px;
  width: calc(20vw - 40px);
`

const CurrentChargePeriodTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
`

const CurrentChargePeriodTime = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
`

const CurrentChargePeriodStatCard = styled.div`
  border-radius: 24px;
  padding: 14px;
  background-color: ${props => props.theme.colors.warning};
  display: flex;
  position: relative;
`

const CurrentChargePeriodStatCardIconContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 28px;
  height: 28px;
  padding: 7px;
  border-radius: 100px;
`

const CurrentChargePeriodStatCardTitle = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.dark};
  margin: 0px;
  margin-left: 18px;
`

const CurrentChargePeriodStatCardValue = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.theme.colors.dark};
  margin: 0px;
  margin-left: 18px;
`

const CurrentChargePeriodStatCardUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  padding-left: 6px;
`

const CurrentChargeChargeDetailContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const CurrentChargeChargeDetailLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
  margin-left: 8px;
`

const CurrentChargeChargeDetailValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  right: 0px;
  margin: 0px;
  margin-top: 4px;
`

const ChargeDetailContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const ChargeDetailLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
  margin-left: 8px;
`

const ChargeDetailValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  right: 0px;
  margin: 0px;
  margin-top: 4px;
`

const AutostartSwitchContainer = styled.div`
  display: flex;
  position: absolute;
  left: calc(12.5vw + 18px + 18px);
  bottom: 18px;
`

const RfidSwitchContainer = styled.div`
  display: flex;
  position: absolute;
  left: calc(12.5vw + 18px + 18px + 180px);
  bottom: 18px;
`

const ChargerBlockedSwitchContainer = styled.div`
  display: flex;
  position: absolute;
  left: calc(12.5vw + 18px + 18px + 320px);
  bottom: 18px;
`

const KwhPriceInputContainer = styled.div<{ $isSelected: boolean }>`
  width: calc(100% - 16px);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  outline: 2px solid ${props => props.$isSelected ? props.theme.colors.primary : props.theme.colors.gray_300};
  box-shadow: 0px 0px ${props => props.$isSelected ? '6' : '0'}px ${props => props.theme.colors.primary};
`

export default function GeneralInformation({ charger, isAdmin }: { charger: ChargerType, isAdmin: boolean }) {
  const { t } = useTranslation()

  const [charges, setCharges] = useState<ChargeType[]>()
  const [chargerGroup, setChargerGroup] = useState<string>()

  const [evseState, setEvseState] = useState<EvseStateType | null>()
  const [chargerComments, setChargerComments] = useState<{ [key: string]: { author: string, comment: string } }>()
  const [isShowingNewCommentModal, setIsShowingNewCommentModal] = useState<boolean>(false)
  const [newCommentInputValue, setNewCommentInputValue] = useState<string>()
  const [chargerDetails, setChargerDetails] = useState<{ ipAddress: string, macAddress: string, connection: string, dlmState: string, midMeterState: string, lifetimeRealySwitches: number, lifetimeChgSec: number, lifetimeWh: number, rfidAccessMode: 0 | 1 | 2 | 3 | 4, rfidAvailable: boolean }>()
  const [isShowingCurrentChargeDetails, setIsShowingCurrentChargeDetails] = useState<boolean>(false)
  const [currentChargeDetails, setCurrentChargeDetails] = useState<ChargeType>()
  const [currentChargeDetailsLastRefresh, setCurrentChargeDetailsLastRefresh] = useState<number>()
  const [chargerNameInputValue, setChargerNameInputValue] = useState<string>(charger.localName)

  const [specificKwhPrice, setSpecificKwhPrice] = useState<{ currency: string, value: number }>()
  const [uniformKwhPrice, setUniformKwhPrice] = useState<{ currency: string, value: number }>()
  const [isShowingKwhPriceModal, setIsShowingKwhPriceModal] = useState<boolean>(false)
  const [isKwhPriceUniform, setIsKwhPriceUniform] = useState<boolean>(false)

  async function fetchCharges() {
    const response = await Comm('/api-cdr/cdrs/last', {
      headers: { "Charger_ID": charger.chargerId }
    })
    const result = await response.json()
    result.reverse()
    setCharges(result)
  }

  async function fetchEvseState() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 3 })
    })

    if (response.ok) {
      const result = await response.json()
      console.log(result)
      const { session, ...resultWithoutSession } = result
      setEvseState(resultWithoutSession)

      // Set current charge details if charger is charging
      // (A charger can also mean its not currently charging but is available to charge (evse_state 2), so we dont check if chg_on)
      if (charger.online && (result.evse_state == 2 || result.evse_state == 3 || result.evse_state == 4) && /*result.chg_on &&*/ result.session) {
        setCurrentChargeDetails(result.session)
      } else {
        setIsShowingCurrentChargeDetails(false)
      }
    }
  }

  async function fetchChargerComments() {
    const dbRef = ref(getDatabase(fbConf))
    const response = await get(child(dbRef, 'voltie/updategroups'))
    const result = response.val()

    for (const group in result) {
      for (const chargerInGroup in result[group].chargers) {
        if (chargerInGroup == charger.chargerId) {
          console.log(chargerInGroup)
          console.log(result[group].chargers[chargerInGroup])
          setChargerGroup(group)
          if (result[group].chargers[chargerInGroup].comments) {
            setChargerComments(result[group].chargers[chargerInGroup].comments)
          }
        }
      }
    }
  }

  async function fetchChargerDetails() {
    const CMD_GET_PRODUCT_NFO_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 12 })
    })
    const CMD_GET_PRODUCT_NFO_result = await CMD_GET_PRODUCT_NFO_response.json()

    const CMD_GET_CONNECTION_STAT_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 22 })
    })
    const CMD_GET_CONNECTION_STAT_result = await CMD_GET_CONNECTION_STAT_response.json()

    const CMD_RFID_GETLIST_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 23 })
    })
    const CMD_RFID_GETLIST_result = await CMD_RFID_GETLIST_response.json()
    console.log(1, CMD_RFID_GETLIST_result)

    let connectionMode = ''
    switch (CMD_GET_CONNECTION_STAT_result.active_nwi) {
      case 1:
      case 2:
        connectionMode = 'ethernet'
        break;
      case 3:
        connectionMode = 'wifi'
        break;
      case 4:
      case 5:
      case 6:
        connectionMode = 'gsm'
        break;
      default:
        connectionMode = 'unknown'
        break;
    }

    setChargerDetails({
      ipAddress: connectionMode == 'ethernet' ? CMD_GET_CONNECTION_STAT_result.ethernet.ip_address : connectionMode == 'wifi' ? CMD_GET_CONNECTION_STAT_result.wifi.ip : t(`charger.unknown`),
      macAddress: connectionMode == 'ethernet' ? CMD_GET_CONNECTION_STAT_result.ethernet.mac_address : connectionMode == 'wifi' ? CMD_GET_CONNECTION_STAT_result.wifi.mac : t(`charger.unknown`),
      connection: t(`charger.${connectionMode}`),
      dlmState: CMD_GET_PRODUCT_NFO_result.dlm_state.toString(),
      midMeterState: CMD_GET_PRODUCT_NFO_result.mid_state.toString(),
      lifetimeRealySwitches: CMD_GET_PRODUCT_NFO_result.lifetime.lifetime_switch,
      lifetimeChgSec: CMD_GET_PRODUCT_NFO_result.lifetime.lifetime_chg_sec,
      lifetimeWh: CMD_GET_PRODUCT_NFO_result.lifetime.lifetime_Wh,
      rfidAccessMode: CMD_RFID_GETLIST_result.rfid_stat.access_mode,
      rfidAvailable: CMD_RFID_GETLIST_result.rfid_stat.rfid_available
    })
  }

  async function fetchKwhPrice() {
    const dbRef = ref(getDatabase(fbConf))

    const specificKwhPriceResponse = await get(child(dbRef, `voltie/chargerusers/${isAdmin ? charger.userId : getAuth(fbConf).currentUser?.uid}/chargers/${charger.chargerId}/kwh_price`))
    setIsKwhPriceUniform(!specificKwhPriceResponse.exists())
    setSpecificKwhPrice(specificKwhPriceResponse.val())

    const uniformKwhPriceResponse = await get(child(dbRef, `voltie/chargerusers/${isAdmin ? charger.userId : getAuth(fbConf).currentUser?.uid}/preferences/calculation_values/price`))
    setUniformKwhPrice(uniformKwhPriceResponse.val())
  }

  async function saveKwhPrice() {
    if (isAdmin) {
      alert("You are not permitted to set kWh prices as an admin")
      fetchKwhPrice()
    } else {
      await set(ref(getDatabase(fbConf), `voltie/chargerusers/${getAuth(fbConf).currentUser?.uid}/preferences/calculation_values/price`), uniformKwhPrice)
      
      if (isKwhPriceUniform) {
        await set(ref(getDatabase(fbConf), `voltie/chargerusers/${getAuth(fbConf).currentUser?.uid}/chargers/${charger.chargerId}/kwh_price`), null)
      } else {
        await set(ref(getDatabase(fbConf), `voltie/chargerusers/${getAuth(fbConf).currentUser?.uid}/chargers/${charger.chargerId}/kwh_price`), specificKwhPrice)
      }
    }

    setIsShowingKwhPriceModal(false)
  }

  useEffect(() => {
    async function fetchCharger() {
      await fetchCharges()
      await fetchKwhPrice()

      // Only fetch comments for admins for now, firebase is not responding to users
      if (isAdmin) {
        await fetchChargerComments()
      }

      if (charger.online) {
        await fetchEvseState()
        await fetchChargerDetails()
      }
    }
    fetchCharger()
  }, [])

  function chargerDlmAndMidStateCodeToMeaning(code: string) {
    switch (parseInt(code)) {
      case 0: return t('charger.dlmMidStates.UNAVAILABLE_ERROR')
      case 1: return t('charger.dlmMidStates.UNAVAILABLE')
      case 2: return t('charger.dlmMidStates.CONNECTED_NOT_IN_USE')
      case 3: return t('charger.dlmMidStates.CONNECTED_IN_USE')
    }
  }

  async function startCharging() {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 5 })
    })
    setEvseState(null)
    setTimeout(fetchEvseState, 3000)
  }

  async function stopCharging() {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 6 })
    })
    setEvseState(null)
    setTimeout(fetchEvseState, 3000)
  }

  async function addChargerComment() {
    await set(ref(getDatabase(fbConf), `voltie/updategroups/${chargerGroup}/chargers/${charger.chargerId}/comments/${Date.now()}`), {
      author: getAuth(fbConf).currentUser?.email,
      comment: newCommentInputValue || ''
    })
    setIsShowingNewCommentModal(false)
    setNewCommentInputValue('')
    fetchChargerComments()
  }

  async function handleUpdateChargerName() {
    if (chargerNameInputValue.length > 0) {
      if (isAdmin) {
        await Comm('/api-charger/charger/update', {
          method: 'PUT',
          body: JSON.stringify({
            chargerId: charger.chargerId,
            serialNumber: charger.serialNumber,
            firmwareVersion: '',
            softwareVersion: '',
            osVersion: '',
            localName: chargerNameInputValue,
            initedCharger: {
              chargerId: '',
              userId: ''
            }
          }),
          headers: {
            'charger_id': charger.chargerId,
            'Content-Type': 'application/json'
          }
        })
      } else {
        await set(ref(getDatabase(fbConf), `voltie/chargerusers/${getAuth(fbConf).currentUser?.uid}/chargers/${charger.chargerId}/name`), chargerNameInputValue)
      }
    } else {
      setChargerNameInputValue(charger.localName)
    }
  }

  async function handleChargerUpdateBlocked(blocked: boolean) {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 62, "available": !blocked })
    })
    setEvseState(null)
    setTimeout(async () => {
      await fetchChargerDetails()
      fetchEvseState()
    }, 3000)
  }

  async function handleUpdateAutostart(autostart: boolean) {
    if (evseState && (evseState.autostart === 1 || evseState.autostart === 0)) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 4, "chg_mode": autostart ? 1 : 0 })
      })
    }
  }

  async function handleUpdateRFIDReader(rfidReaderOn: boolean) {
    if (chargerDetails && chargerDetails.rfidAvailable && (chargerDetails.rfidAccessMode === 0 || chargerDetails.rfidAccessMode === 1)) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 65, "access_mode": rfidReaderOn ? 1 : 0 })
      })
    }
  }

  // Fetch current charge details every 5 seconds if the current charge details modal is open
  useEffect(() => {
    const evseStateRefresh = setTimeout(() => {
      if (isShowingCurrentChargeDetails) {
        fetchEvseState()
        setCurrentChargeDetailsLastRefresh(Date.now())
      }
    }, 5000)

    return () => clearTimeout(evseStateRefresh)
  }, [currentChargeDetailsLastRefresh, isShowingCurrentChargeDetails])

  return (
    <>
      {isShowingKwhPriceModal && (
        <Modal onEscape={() => { setIsShowingKwhPriceModal(false); fetchKwhPrice()}}>
          <SmallModalContainer>
            <KwhPriceInputContainer $isSelected={isKwhPriceUniform} onClick={() => setIsKwhPriceUniform(true)}>
              <InputWithLabel label={`${t('charger.uniform')} ${t('charger.kwhPrice')}`} width={"77%"} value={(uniformKwhPrice?.value || 0).toString()} onInput={value => setUniformKwhPrice({ value: parseFloat(value) || 0, currency: uniformKwhPrice?.currency || '' })} />
              <WhiteSpace width="8px" />
              <Select width={""} options={[uniformKwhPrice?.currency == 'euro' ? 'EUR' : 'HUF', 'HUF', 'EUR']} handleSelect={value => setUniformKwhPrice({value: uniformKwhPrice?.value || 0, currency: value == 'HUF' ? 'forint' : value == 'EUR' ? 'euro' : ''})} isAbsolute />
            </KwhPriceInputContainer>
            <WhiteSpace height="16px" />

            <KwhPriceInputContainer $isSelected={!isKwhPriceUniform} onClick={() => setIsKwhPriceUniform(false)}>
              <InputWithLabel label={`${t('charger.chargerSpecific')} ${t('charger.kwhPrice')}`} width={"77%"} value={(specificKwhPrice?.value || 0).toString()} onInput={value => setSpecificKwhPrice({ value: parseFloat(value) || 0, currency: specificKwhPrice?.currency || '' })} />
              <WhiteSpace width="8px" />
              <Select width={""} options={[specificKwhPrice?.currency == 'euro' ? 'EUR' : 'HUF', 'HUF', 'EUR']} handleSelect={value => setSpecificKwhPrice({value: specificKwhPrice?.value || 0, currency: value == 'HUF' ? 'forint' : value == 'EUR' ? 'euro' : ''})} isAbsolute />
            </KwhPriceInputContainer>

            <WhiteSpace height="16px" />
            <SmallModalButtonContainer>
              <Button onclick={saveKwhPrice} label={t('charger.save')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => { setIsShowingKwhPriceModal(false); fetchKwhPrice()}} label={t('charger.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      {isShowingNewCommentModal && (
        <Modal onEscape={() => setIsShowingNewCommentModal(false)}>
          <SmallModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingNewCommentModal(false)} />
            </ModalCloseButtonContainer>
            <SmallModalLabel>{t('charger.addNewComment')}</SmallModalLabel>
            <WhiteSpace height="16px" />
            <AddCommentTextArea value={newCommentInputValue} onInput={(e) => setNewCommentInputValue((e.target as HTMLInputElement).value)}></AddCommentTextArea>
            <WhiteSpace height="8px" />
            <SmallModalButtonContainer>
              <Button onclick={addChargerComment} label={t('charger.add')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsShowingNewCommentModal(false)} label={t('charger.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      {isShowingCurrentChargeDetails && currentChargeDetails && (
        <Modal align="right" onEscape={() => setIsShowingCurrentChargeDetails(false)}>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingCurrentChargeDetails(false)} />
            </ModalCloseButtonContainer>
            <ModalTitle>{t('charger.chargingInProgress')}</ModalTitle>
            <WhiteSpace height="12px" />
            <div style={{ display: "flex", width: "95%", alignItems: "center", justifyContent: "center" }}>
              <Icon path="chargers.power" width="28px" height="28px" color="success" style={{ filter: 'drop-shadow(0px 0px 6px #57D9BA)' }} />
              <WhiteSpace width="8px" />
              <CurrentChargeLabel>{t('charger.charging')}</CurrentChargeLabel>
            </div>

            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", marginTop: "-12px" }}>
              {currentChargeDetails.idtag_name && currentChargeDetails.idtag_name != '' && <>
                <Icon path="chargers.rfidTag" width="18px" height="18px" color="white" />
                <WhiteSpace width="4px" />
                <StatusLabel $color="#181C32">{currentChargeDetails.idtag_name || '-'}</StatusLabel>
                <WhiteSpace width="24px" />
              </>}
              <CurrentChargeElaplesTimeLabel>{`${t('charger.elapsedTime')}: ${new Date(currentChargeDetails.chg_time * 1000).toISOString().slice(11, 19)}`}</CurrentChargeElaplesTimeLabel>
            </div>

            <WhiteSpace height="18px" />
            <div style={{ display: 'flex', width: "100%" }}>
              <WhiteSpace width='3%' />
              <SlideToConfirm width='94%' height='48px' label="Slide to STOP charging" onConfirm={stopCharging} icon="chargers.power" iconColor="primary" bgColorLeft="#2A6FF4" bgColorRight="#E2594B" />
            </div>
            <WhiteSpace height="24px" />

            <CurrentChargePeriodStatCard>
              <CurrentChargePeriodStatCardIconContainer>
                <Icon path="chargers.energy" color="warning" width="100%" height="100%" />
              </CurrentChargePeriodStatCardIconContainer>
              <div>
                <CurrentChargePeriodStatCardTitle>{t('charger.allEnergy')}</CurrentChargePeriodStatCardTitle>
                <CurrentChargePeriodStatCardValue>
                  {currentChargeDetails.chg_energy}
                  <CurrentChargePeriodStatCardUnit>kWh</CurrentChargePeriodStatCardUnit>
                </CurrentChargePeriodStatCardValue>
              </div>
            </CurrentChargePeriodStatCard>

            <WhiteSpace height="24px" />

            <div style={{ overflow: 'scroll', height: 'calc(85vh - 48px - 253px)', marginLeft: '-24px', paddingLeft: '24px', marginRight: '-24px', paddingRight: '24px' }}>

              {[
                { icon: "chargers.pluggedIn", iconColor: 'gray_700', label: t('charger.pluggedIn'), value: new Date(currentChargeDetails.s_start * 1000).toLocaleString('hu') },
                { icon: "chargers.sleep", iconColor: 'gray_500', label: t('charger.idleTime'), value: secondsToHumanReadable(currentChargeDetails.idle_time) },
                { icon: "chargers.average", label: t('charger.averagePower'), value: `${currentChargeDetails.avg_power} kW` },
                { icon: "chargers.power", iconColor: 'warning', label: t('charger.currentPower'), value: `${evseState?.chg_power} kW` || '-' },
                { icon: "settings.voltage", iconColor: 'warning', label: t('charger.voltagePhase'), value: `${evseState?.u_eff} V` || '-' },
                { icon: "settings.amper", iconColor: 'primary', label: `${t('charger.amper')} (${t('charger.current')}/max)`, value: `${evseState?.chg_current} A / ${evseState?.max_current} A` || '-' },
                { icon: "chargers.humidity", iconColor: 'primary', label: `${t('charger.humidity')} (max/min)`, value: `${currentChargeDetails.hum_max}% / ${currentChargeDetails.hum_min}%` },
                { icon: "general.more", iconColor: 'gray_700', label: t('charger.phase'), value: evseState?.phase || '-' },
                { icon: "chargers.power", iconColor: 'gray_700', label: t('charger.autostart'), value: (evseState?.autostart === 1 ? t('general.on') : t('general.off')) || '-' },
                { adminOnly: true, icon: "chargers.thermometer", iconColor: 'danger', label: `MCU ${t('charger.temperature')} (${t('charger.avg')}/max)`, value: `${currentChargeDetails.temp_uc_avg.toFixed(1)} °C / ${currentChargeDetails.temp_uc_max.toFixed(1)} °C` || '-' },
                { adminOnly: true, icon: "chargers.thermometer", iconColor: 'danger', label: `POW ${t('charger.temperature')} (${t('charger.avg')}/max)`, value: `${currentChargeDetails.temp_pow_avg.toFixed(1)} °C / ${currentChargeDetails.temp_pow_max.toFixed(1)} °C` || '-' },
                { adminOnly: true, icon: "chargers.thermometer", iconColor: 'danger', label: `PI ${t('charger.temperature')} (${t('charger.avg')}/max)`, value: `${currentChargeDetails.temp_pi_avg.toFixed(1)} °C / ${currentChargeDetails.temp_pi_max.toFixed(1)} °C` || '-' },
              ].map(dataPoint => ((isAdmin && dataPoint.adminOnly) || !dataPoint.adminOnly) && (
                <React.Fragment >
                  <ChargeDetailContainer key={dataPoint.label}>
                    <Icon path={dataPoint.icon} color={dataPoint.iconColor} width="24px" height="24px" />
                    <ChargeDetailLabel>{dataPoint.label}</ChargeDetailLabel>
                    <ChargeDetailValue>{dataPoint.value}</ChargeDetailValue>
                  </ChargeDetailContainer>
                  <WhiteSpace height="16px" />
                </React.Fragment>
              ))}

              <div style={{ width: '100%', backgroundColor: '#E2E8F0', height: '1px', marginTop: '16px', marginBottom: '16px' }} />

              <CurrentChargePeriodContainer>
                <CurrentChargePeriodTitle>{t('charger.currentPeriod')}</CurrentChargePeriodTitle>
                <div style={{ width: '100%', backgroundColor: '#E2E8F0', height: '1px', marginTop: '16px', marginBottom: '16px' }} />
                <CurrentChargeChargeDetailContainer>
                  <Icon path='chargers.energy' width="24px" height="24px" color="warning" />
                  <CurrentChargeChargeDetailLabel>{t('charger.currentEnergy')}</CurrentChargeChargeDetailLabel>
                  <CurrentChargeChargeDetailValue>{`${currentChargeDetails.periods.at(-1)?.p_energy} kWh`}</CurrentChargeChargeDetailValue>
                </CurrentChargeChargeDetailContainer>
                <WhiteSpace height="16px" />
                <CurrentChargeChargeDetailContainer>
                  <Icon path='chargers.time' width="24px" height="24px" color="primary" />
                  <CurrentChargeChargeDetailLabel>{t('charger.startTime')}</CurrentChargeChargeDetailLabel>
                  <CurrentChargeChargeDetailValue>{new Date((currentChargeDetails.periods.at(-1)?.p_start || 0) * 1000).toLocaleTimeString('hu')}</CurrentChargeChargeDetailValue>
                </CurrentChargeChargeDetailContainer>
              </CurrentChargePeriodContainer>
              {structuredClone(currentChargeDetails.periods).reverse().map(period => period.index != currentChargeDetails.periods.length - 1 && (
                <>
                  <WhiteSpace height="16px" />
                  <CurrentChargePeriodContainer>
                    <CurrentChargePeriodTitle>{`${t('charger.period')} ${period.index + 1}`}</CurrentChargePeriodTitle>
                    <CurrentChargePeriodTime>{`${new Date(period.p_start * 1000).toLocaleTimeString('hu').slice(0, -3)} - ${new Date(period.p_end * 1000).toLocaleTimeString('hu').slice(0, -3)}`}</CurrentChargePeriodTime>
                    <div style={{ width: '100%', backgroundColor: '#E2E8F0', height: '1px', marginTop: '16px', marginBottom: '16px' }} />
                    <CurrentChargeChargeDetailContainer>
                      <Icon path='chargers.energy' width="24px" height="24px" color="warning" />
                      <CurrentChargeChargeDetailLabel>{t('charger.energy')}</CurrentChargeChargeDetailLabel>
                      <CurrentChargeChargeDetailValue>{`${period.p_energy} kWh`}</CurrentChargeChargeDetailValue>
                    </CurrentChargeChargeDetailContainer>
                    <WhiteSpace height="16px" />
                    <CurrentChargeChargeDetailContainer>
                      <Icon path='chargers.time' width="24px" height="24px" color="primary" />
                      <CurrentChargeChargeDetailLabel>{t('charger.chargeTime')}</CurrentChargeChargeDetailLabel>
                      <CurrentChargeChargeDetailValue>{secondsToHumanReadable(period.p_end - period.p_start)}</CurrentChargeChargeDetailValue>
                    </CurrentChargeChargeDetailContainer>
                  </CurrentChargePeriodContainer>
                </>
              ))}
              <WhiteSpace height="16px" />
            </div>
          </ModalContainer>
        </Modal>
      )}
      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.generalInformation')} />
      <WhiteSpace height={'12px'} />

      <div style={{ display: 'flex' }}>
        <GeneralContainer>

          {/* OFFLINE */}
          {!charger.online && (
            <StatusContainer $bgColorTop="#E3E9F1" $bgColorBottom="#E3E9F1">
              <StatusTitle $bgColor="#E3E9F1" $fontColor="#7E8299">OFFLINE</StatusTitle>
              <StatusOfflineLabel>{dateToTimeAgoString(new Date(charger.lastPresence.replace(/\./g, '-')))}</StatusOfflineLabel>
              <WhiteSpace height="20px" />
              <Icon path="chargers.chargerStatusOffline" width="7vw" height="7vw" marginleft="calc((100% - 7vw) / 2)" />
            </StatusContainer>
          )}

          {/* LOADING */}
          {charger.online && !evseState && (
            <StatusContainer $bgColorTop="#E3E9F1" $bgColorBottom="#E3E9F1">
              <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                <WhiteSpace height="40%" />
                <span style={{ fontWeight: "700", fontSize: "24px" }}>
                  <LoaderDots />
                </span>
              </div>
            </StatusContainer>
          )}

          {/* NOT CONNECTED */}
          {charger.online && evseState?.evse_state == 1 && (
            <StatusContainer $bgColorTop="#57D9BA" $bgColorBottom="#09BCA6">
              <StatusTitle $bgColor="#57D9BA" $fontColor="#FFFFFF">ONLINE</StatusTitle>
              <WhiteSpace height="2vmax" />
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Icon path="chargers.unplugged" width="16px" height="16px" />
                <WhiteSpace width="8px" />
                <StatusLabel $color="#4F8378">{t('charger.carUnplugged')}</StatusLabel>
              </div>
              <WhiteSpace height="1vmax" />
              <Icon path="chargers.chargerStatusOnline" width="7vw" height="7vw" marginleft="calc((100% - 7vw) / 2)" />
            </StatusContainer>
          )}

          {/* CONNECTED - NOT AVAILABLE TO CHARGE (OR SO I WAS THINKING, BUT IT TURNS OUT IT COULD ALSO START A CHARGE?) */}
          {charger.online && evseState?.evse_state == 2 && (
            <StatusContainer $bgColorTop="#57D9BA" $bgColorBottom="#09BCA6">
              <StatusTitle $bgColor="#57D9BA" $fontColor="#FFFFFF">ONLINE</StatusTitle>
              <WhiteSpace height="1.5vmax" />
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Icon path="chargers.pluggedIn" width="16px" height="16px" />
                <WhiteSpace width="8px" />
                <StatusLabel $color="#181C32">{t('charger.carPluggedIn')}</StatusLabel>
              </div>
              <WhiteSpace height=".5vmax" />
              {currentChargeDetails && currentChargeDetails.idtag_name && currentChargeDetails.idtag_name != '' ? <>
                <Icon path="chargers.chargerStatusOnline" width="5.5vw" height="5.5vw" marginleft="calc((100% - 5.5vw) / 2)" />
                <WhiteSpace height=".4vmax" />
                <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                  <Icon path="chargers.rfidTag" width="18px" height="18px" color="white" />
                  <WhiteSpace width="4px" />
                  <StatusLabel $color="white">{currentChargeDetails.idtag_name || '-'}</StatusLabel>
                </div>
              </> : <>
                <Icon path="chargers.chargerStatusOnline" width="7vw" height="7vw" marginleft="calc((100% - 7vw) / 2)" />
              </>}


              {/* Added this part so user can start a charge in this mode too */}
              <div style={{ display: 'flex', position: "absolute", width: "100%", bottom: "3%" }}>
                <WhiteSpace width='3%' />
                <SlideToConfirm width='94%' label="Slide to START charging" onConfirm={startCharging} icon="chargers.power" iconColor="primary" bgColorLeft="#2A6FF4" bgColorRight="#56F2DF" />
              </div>


            </StatusContainer>
          )}

          {/* CONNECTED - AVAILABLE TO CHARGE */}
          {charger.online && (evseState?.evse_state == 3 || evseState?.evse_state == 4) && !evseState.chg_on && (
            <StatusContainer $bgColorTop="#57D9BA" $bgColorBottom="#09BCA6">
              <StatusTitle $bgColor="#57D9BA" $fontColor="#FFFFFF">ONLINE</StatusTitle>
              <WhiteSpace height="1.5vmax" />
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Icon path="chargers.pluggedIn" width="16px" height="16px" />
                <WhiteSpace width="8px" />
                <StatusLabel $color="#181C32">{t('charger.carPluggedIn')}</StatusLabel>
              </div>
              <WhiteSpace height=".5vmax" />
              {currentChargeDetails && currentChargeDetails.idtag_name && currentChargeDetails.idtag_name != '' ? <>
                <Icon path="chargers.chargerStatusOnline" width="5.5vw" height="5.5vw" marginleft="calc((100% - 5.5vw) / 2)" />
                <WhiteSpace height=".4vmax" />
                <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                  <Icon path="chargers.rfidTag" width="18px" height="18px" color="white" />
                  <WhiteSpace width="4px" />
                  <StatusLabel $color="white">{currentChargeDetails.idtag_name || '-'}</StatusLabel>
                </div>
              </> : <>
                <Icon path="chargers.chargerStatusOnline" width="7vw" height="7vw" marginleft="calc((100% - 7vw) / 2)" />
              </>}
              <div style={{ display: 'flex', position: "absolute", width: "100%", bottom: "3%" }}>
                <WhiteSpace width='3%' />
                <SlideToConfirm width='94%' label="Slide to START charging" onConfirm={startCharging} icon="chargers.power" iconColor="primary" bgColorLeft="#2A6FF4" bgColorRight="#56F2DF" />
              </div>
            </StatusContainer>
          )}

          {/* CHARGING */}
          {charger.online && (evseState?.evse_state == 3 || evseState?.evse_state == 4) && evseState.chg_on && (
            <StatusContainer $bgColorTop="#2B71F5" $bgColorBottom="#50D7DF">
              <StatusTitle $bgColor="#2B71F5" $fontColor="#FFFFFF">{t('charger.CHARGING')}</StatusTitle>
              <WhiteSpace height="1.5vmax" />
              {currentChargeDetails && currentChargeDetails.idtag_name && currentChargeDetails.idtag_name != '' ? <>
                <Icon path="chargers.chargerStatusCharging" width="5.5vw" height="5.5vw" marginleft="calc((100% - 5.5vw) / 2)" />
                <WhiteSpace height=".5vmax" />
                <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                  <Icon path="chargers.rfidTag" width="18px" height="18px" color="white" />
                  <WhiteSpace width="4px" />
                  <StatusLabel $color="white">{currentChargeDetails.idtag_name || '-'}</StatusLabel>
                </div>
              </> : <>
                <Icon path="chargers.chargerStatusCharging" width="7vw" height="7vw" marginleft="calc((100% - 7vw) / 2)" />
              </>}
              <WhiteSpace height=".25vmax" />
              <div onClick={() => setIsShowingCurrentChargeDetails(true)} style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                <StatusLabel $color="white">{t('charger.chargingInfo')}</StatusLabel>
                <WhiteSpace width="2px" />
                <Icon path="general.forwardArrow" width="16px" height="16px" color="white" />
              </div>
              <div style={{ display: 'flex', position: "absolute", width: "100%", bottom: "3%" }}>
                <WhiteSpace width='3%' />
                <SlideToConfirm width='94%' label="Slide to STOP charging" onConfirm={stopCharging} icon="chargers.power" iconColor="primary" bgColorLeft="#2A6FF4" bgColorRight="#E2594B" />
              </div>
            </StatusContainer>
          )}

          {/* BLOCKED */}
          {charger.online && evseState?.evse_state == 18 && (
            <StatusContainer $bgColorTop="#505369" $bgColorBottom="#7F8291">
              <StatusTitle $bgColor="#181C32" $fontColor="#FFFFFF">BLOCKED</StatusTitle>
              <WhiteSpace height="2vmax" />
              <Icon path="chargers.chargerStatusBlocked" width="7vw" height="7vw" marginleft="calc((100% - 7vw) / 2)" />
              <WhiteSpace height="1vmax" />
              <div onClick={() => handleChargerUpdateBlocked(false)} style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                <StatusLabel $color="#FFFFFF">Unblock</StatusLabel>
                <WhiteSpace width="2px" />
                <Icon path="general.forwardArrow" width="16px" height="16px" color="white" />
              </div>
            </StatusContainer>
          )}

          {/* ERROR */}
          {charger.online && evseState && evseState.evse_state != 1 && evseState.evse_state != 2 && evseState.evse_state != 3 && evseState.evse_state != 4 && evseState.evse_state != 18 && (
            <StatusContainer $bgColorTop="#EB5172" $bgColorBottom="#FD4E4E">
              <StatusTitle $bgColor="#EB5172" $fontColor="#FFFFFF">ERROR</StatusTitle>
              <WhiteSpace height="2vmax" />
              <Icon path="chargers.chargerStatusError" width="7vw" height="7vw" marginleft="calc((100% - 7vw) / 2)" />
              <WhiteSpace height="1vmax" />
              <Navigator to={`/chargers/${charger.chargerId}/logs`}>
                <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                  <StatusLabel $color="#FFFFFF">Check LOGs</StatusLabel>
                  <WhiteSpace width="2px" />
                  <Icon path="general.forwardArrow" width="16px" height="16px" color="white" />
                </div>
              </Navigator>
            </StatusContainer>
          )}


          <ChargerNameLabel>{t('charger.chargerName')}</ChargerNameLabel>
          <ChargerNameValue $width={isAdmin ? '30%' : '70%'} value={chargerNameInputValue} onInput={(e) => setChargerNameInputValue((e.target as HTMLInputElement).value)} onBlur={handleUpdateChargerName} onKeyDown={(e) => { if (e.key === "Enter") (e.target as HTMLInputElement).blur() }} spellCheck={false} />
          {/* Only show the user firebase node button to admins */}
          {isAdmin && <>
            <UserIdEmailLabel>{t('charger.userIdEmail')}</UserIdEmailLabel>
            <UserIdEmailValue>
              <Navigator newTab to={`https://console.firebase.google.com/project/voltie-charger/database/voltie-charger-default-rtdb/data/~2Fvoltie~2Fchargerusers~2F${charger.userId}`}>
                {charger.userId}<Icon path="general.forwardArrow" width="16px" height="16px" marginleft="5px" />
              </Navigator>
              <br />{charger.userEmail}
            </UserIdEmailValue>
          </>}
          <GeneralDetailsTabel>
            <tbody>
              <tr>
                {/* Only show charger group to admins */}
                {isAdmin && (
                  <td>
                    <GeneralDetailLabel>{t('charger.group')}</GeneralDetailLabel>
                    <GeneralDetailValue>{chargerGroup || '-'}</GeneralDetailValue>
                  </td>
                )}
                <td>
                  <GeneralDetailLabel>{t('charger.chargerId')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.chargerId}</GeneralDetailValue>
                </td>
                {/* Only show registation date to admins */}
                {isAdmin && (
                  <td>
                    <GeneralDetailLabel>{t('charger.registrationDate')}</GeneralDetailLabel>
                    <GeneralDetailValue>{charger.registrationDate}</GeneralDetailValue>
                  </td>
                )}
                <td>
                  <GeneralDetailLabel>{t('charger.serialNumber')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-'}</GeneralDetailValue>
                </td>
                <td onClick={() => setIsShowingKwhPriceModal(true)} style={{ cursor: 'pointer' }}>
                  <div style={{ display: 'flex' }}>
                    <GeneralDetailLabel>{t('charger.kwhPrice')}</GeneralDetailLabel>
                    <Icon path="general.edit" width="10px" height="10px" margintop="3px" marginleft="6px" color="gray_600" />
                  </div> 
                  <GeneralDetailValue>{!isKwhPriceUniform ? `${specificKwhPrice?.value.toString() || '-'} ${specificKwhPrice?.currency == 'forint' ? 'HUF' : specificKwhPrice?.currency == 'euro' ? 'EUR' : ''}` : `${uniformKwhPrice?.value.toString() || '-'} ${uniformKwhPrice?.currency == 'forint' ? 'HUF' : uniformKwhPrice?.currency == 'euro' ? 'EUR' : ''}`}</GeneralDetailValue>
                </td>
              </tr>
            </tbody>
          </GeneralDetailsTabel>
          <GeneralDetailsDivider />
          {evseState && chargerDetails && <>
            <AutostartSwitchContainer style={chargerDetails.rfidAccessMode === 0 && (evseState.autostart === 1 || evseState.autostart === 0) ? {} : { pointerEvents: 'none', opacity: 0.5 }}>
              <GeneralDetailValue style={{ marginTop: '4px' }}>{t('charger.autostart')}</GeneralDetailValue>
              <WhiteSpace width="12px" />
              <Switch toggled={evseState.autostart === 1} setToggled={(value) => { setEvseState({ ...evseState, autostart: value ? 1 : 0 }); handleUpdateAutostart(value) }} />
            </AutostartSwitchContainer>
            <RfidSwitchContainer style={(chargerDetails.rfidAvailable && (chargerDetails.rfidAccessMode === 1 || chargerDetails.rfidAccessMode === 0)) ? {} : { pointerEvents: 'none', opacity: 0.5 }}>
              <GeneralDetailValue style={{ marginTop: '4px' }}>{t('charger.rfid')}</GeneralDetailValue>
              <WhiteSpace width="12px" />
              <Switch toggled={chargerDetails.rfidAvailable && chargerDetails.rfidAccessMode === 1} setToggled={(value) => {
                setChargerDetails({ ...chargerDetails, rfidAccessMode: value ? 1 : 0 })
                handleUpdateRFIDReader(value)

                // If RFID is on, autocharge is going to turn off, so we might aswell turn it off now
                if (value == true) {
                  setEvseState({ ...evseState, autostart: 0 })
                } else {
                  setEvseState({ ...evseState, autostart: 1 })
                }
              }} />
            </RfidSwitchContainer>
            <ChargerBlockedSwitchContainer>
              <GeneralDetailValue style={{ marginTop: '4px' }}>{t('charger.available')}</GeneralDetailValue>
              <WhiteSpace width="12px" />
              <Switch toggled={evseState.evse_state != 18} setToggled={(value) => { handleChargerUpdateBlocked(!value) }} />
            </ChargerBlockedSwitchContainer>
          </>}
        </GeneralContainer>
        <StatCardTable>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <StatCard $color="dark">
                  <StatCardIconContainer>
                    <Icon path="chargers.power" width="100%" height="100%" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle $white>{t('charger.allCharges')}</StatCardTitle>
                    <StatCardValue $white>{charges ? charges.length : <LoaderDots />}<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
              <td style={{ width: '50%' }}>
                <StatCard $color="warning">
                  <StatCardIconContainer>
                    <Icon path="chargers.energy" width="100%" height="100%" color="warning" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle>{t('charger.allChargedEnergy')}</StatCardTitle>
                    <StatCardValue>{charger.online ? chargerDetails ? (chargerDetails.lifetimeWh / 1000).toFixed(2) : <LoaderDots /> : '-'}<StatCardUnit>kWh</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
            </tr>
            <tr>
              <td>
                <WhiteSpace height="8px" />
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <StatCard $color="primary">
                  <StatCardIconContainer>
                    <Icon path="chargers.time" width="100%" height="100%" color="primary" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle>{t('charger.allChargetime')}</StatCardTitle>
                    <StatCardValue>{charger.online ? chargerDetails ? secondsToHumanReadable(chargerDetails.lifetimeChgSec) : <LoaderDots /> : '-h'}</StatCardValue>
                  </div>
                </StatCard>
              </td>
              <td style={{ width: '50%' }}>
                <StatCard $color="dark">
                  <StatCardIconContainer>
                    <Icon path="chargers.power" width="100%" height="100%" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle $white>{t('charger.relaySwitches')}</StatCardTitle>
                    <StatCardValue $white>{charger.online ? chargerDetails ? chargerDetails.lifetimeRealySwitches : <LoaderDots /> : '-'}<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
            </tr>
          </tbody>
        </StatCardTable>
      </div>
      <WhiteSpace height="18px" />
      <div style={{ overflow: "scroll", height: "calc(90vh - 12.5vw - 130px)" }}>
        <ConnectionContainer>
          <ConnectionTitle>{t('charger.connection')}</ConnectionTitle>
          <ConnectionTableDivider $left="31%" />
          <ConnectionTableDivider $left="64%" />
          <table style={{ width: '100%' }}>
            <tbody>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.ipAddress')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails?.ipAddress || <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.dlmState')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails ? chargerDlmAndMidStateCodeToMeaning(chargerDetails.dlmState) : <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.software')}</ConnectionLabel>
                  <ConnectionValue>{charger.swVersion}</ConnectionValue>
                </ConnectionTableCell>
              </tr>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.macAddress')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails?.macAddress || <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.midMeterState')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails ? chargerDlmAndMidStateCodeToMeaning(chargerDetails.midMeterState) : <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.firmware')}</ConnectionLabel>
                  <ConnectionValue>{charger.fwVersion}</ConnectionValue>
                </ConnectionTableCell>
              </tr>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.connection')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails?.connection || <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell></ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.operatingSystem')}</ConnectionLabel>
                  <ConnectionValue>{charger.osVersion}</ConnectionValue>
                </ConnectionTableCell>
              </tr>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.serverConnection')}</ConnectionLabel>
                  <ConnectionValue>{charger.lastPresence}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell></ConnectionTableCell>
                <ConnectionTableCell></ConnectionTableCell>
              </tr>
            </tbody>
          </table>
        </ConnectionContainer>
        {/* Only show comments to admins for now, firebase is not respoding to users */}
        {isAdmin && <>
          <WhiteSpace height="18px" />
          <CommentContainer>
            <CommentTitle>{t('charger.comments')}</CommentTitle>
            <WhiteSpace height="8px" />
            <div style={{ position: "absolute", top: '24px', right: '24px' }}>
              <ButtonWithIcon icon="general.plus" label={t('charger.newComment')} onClick={() => setIsShowingNewCommentModal(true)} backgroundColor="dark" iconColor="white" />
            </div>
            {chargerComments && Object.keys(chargerComments).sort().reverse().map((comment) => <React.Fragment key={comment}>
              <div style={{ display: 'flex' }}>
                <CommentAuthorText>{chargerComments[comment].author || '-'}</CommentAuthorText>
                <WhiteSpace width="4px" />
                <CommentDateText>•</CommentDateText>
                <WhiteSpace width="4px" />
                <CommentDateText>{`${new Date(parseInt(comment)).toLocaleDateString('hu').replace(/\. /g, '.') || '-'} ${new Date(parseInt(comment)).toLocaleTimeString('hu') || '-'}`}</CommentDateText>
              </div>
              <WhiteSpace height="8px" />
              <CommentMessageText>{chargerComments[comment].comment || '-'}</CommentMessageText>
              <WhiteSpace height="32px" />
            </React.Fragment>)}
          </CommentContainer>
        </>}
      </div>
    </>
  )
}