import { FC } from 'react'
import { useTheme } from 'styled-components'
import WhiteSpace from './components/Whitespace'
import Search from './assets/icons/search.svg?react'
import Refresh from './assets/icons/refresh.svg?react'
import Edit from './assets/icons/edit.svg?react'
import Charger from './assets/icons/charger.svg?react'
import BackArrow from './assets/icons/backArrow.svg?react'
import ForwardArrow from './assets/icons/forwardArrow.svg?react'
import UpArrow from './assets/icons/upArrow.svg?react'
import DownArrow from './assets/icons/downArrow.svg?react'
import MenuDashboard from './assets/icons/menuDashboard.svg?react'
import MenuDashboardActive from './assets/icons/menuDashboardActive.svg?react'
import MenuChargers from './assets/icons/menuChargers.svg?react'
import MenuChargersActive from './assets/icons/menuChargersActive.svg?react'
import MenuChargergroups from './assets/icons/menuChargergroups.svg?react'
import MenuChargergroupsActive from './assets/icons/menuChargergroupsActive.svg?react'
import MenuLogs from './assets/icons/menuLogs.svg?react'
import MenuLogsActive from './assets/icons/menuLogsActive.svg?react'
import MenuAppversion from './assets/icons/menuAppversion.svg?react'
import MenuAppversionActive from './assets/icons/menuAppversionActive.svg?react'
import MenuUsers from './assets/icons/menuUsers.svg?react'
import MenuUsersActive from './assets/icons/menuUsersActive.svg?react'
import MenuSettings from './assets/icons/menuSettings.svg?react'
import MenuSettingsActive from './assets/icons/menuSettingsActive.svg?react'
import MenuNotifications from './assets/icons/menuNotifications.svg?react'
import MenuNotificationsActive from './assets/icons/menuNotificationsActive.svg?react'
import MenuRfids from './assets/icons/menuRfids.svg?react'
import MenuRfidsActive from './assets/icons/menuRfidsActive.svg?react'
import FlagHun from './assets/icons/flagHun.svg?react'
import FlagUk from './assets/icons/flagUk.svg?react'
import Lightmode from './assets/icons/lightmode.svg?react'
import Darkmode from './assets/icons/darkmode.svg?react'
import Systemtheme from './assets/icons/systemtheme.svg?react'
import Mail from './assets/icons/mail.svg?react'
import ChargerFace from './assets/icons/chargerFace.svg?react'
import ChargerEye from './assets/icons/chargerEye.svg?react'
import ChargerWink from './assets/icons/chargerWink.svg?react'
import Eye from './assets/icons/eye.svg?react'
import EyeDisabled from './assets/icons/eyeDisabled.svg?react'
import LogoDashBoardHorizontal from './assets/icons/logoDashboardHorizontal.svg?react'
import LogoDashBoardVertical from './assets/icons/logoDashboardVertical.svg?react'
import X from './assets/icons/x.svg?react'
import ForgotPassword from './assets/icons/forgotPassword.svg?react'
import CheckYourInbox from './assets/icons/checkYourInbox.svg?react'
import SetNewPassword from './assets/icons/setNewPassword.svg?react'
import Key from './assets/icons/key.svg?react'
import Export from './assets/icons/export.svg?react'
import ChargerOnline from './assets/icons/chargerOnline.svg?react'
import ChargerOffline from './assets/icons/chargerOffline.svg?react'
import Wrench from './assets/icons/wrench.svg?react'
import Trash from './assets/icons/trash.svg?react'
import SW from './assets/icons/SW.svg?react'
import FW from './assets/icons/FW.svg?react'
import OS from './assets/icons/OS.svg?react'
import Filter from './assets/icons/filter.svg?react'
import Upload from './assets/icons/upload.svg?react'
import Plus from './assets/icons/plus.svg?react'
import Installed_chargersGroup from './assets/icons/installed_chargersGroup.svg?react'
import BetatesterGroup from './assets/icons/betatesterGroup.svg?react'
import DefaultGroup from './assets/icons/defaultGroup.svg?react'
import Insider_simuhatGroup from './assets/icons/insider_simuhatGroup.svg?react'
import ChargerGroup from './assets/icons/chargerGroup.svg?react'
import FileUpload from './assets/icons/fileUpload.svg?react'
import More from './assets/icons/more.svg?react'
import Datasheet from './assets/icons/datasheet.svg?react'
import Log from './assets/icons/log.svg?react'
import MoveCharger from './assets/icons/moveCharger.svg?react'
import Error from './assets/icons/error.svg?react'
import CheckboxFull from './assets/icons/checkboxFull.svg?react'
import CheckboxEmpty from './assets/icons/checkboxEmpty.svg?react'
import User from './assets/icons/user.svg?react'
import Calendar from './assets/icons/calendar.svg?react'
import Commands from './assets/icons/commands.svg?react'
import AppleLogo from './assets/icons/appleLogo.svg?react'
import AndroidLogo from './assets/icons/androidLogo.svg?react'
import MinAppVersion from './assets/icons/minAppVersion.svg?react'
import LatestAppVersion from './assets/icons/latestAppVersion.svg?react'
import PluggedIn from './assets/icons/carPluggedIn.svg?react'
import Unplugged from './assets/icons/carUnplugged.svg?react'
import ChargerStatusOnline from './assets/icons/chargerStatusOnline.svg?react'
import ChargerStatusOffline from './assets/icons/chargerStatusOffline.svg?react'
import ChargerStatusCharging from './assets/icons/chargerStatusCharging.svg?react'
import ChargerStatusError from './assets/icons/chargerStatusError.svg?react'
import ChargerStatusBlocked from './assets/icons/chargerStatusBlocked.svg?react'
import Power from './assets/icons/power.svg?react'
import Energy from './assets/icons/energy.svg?react'
import Time from './assets/icons/time.svg?react'
import Average from './assets/icons/average.svg?react'
import Sleep from './assets/icons/sleep.svg?react'
import NormalCharging from './assets/icons/normalCharging.svg?react'
import KWhBasedCharging from './assets/icons/kWhBasedCharging.svg?react'
import ScheduledCharging from './assets/icons/scheduledCharging.svg?react'
import RfidTag from './assets/icons/rfidTag.svg?react'
import SettingsConnections from './assets/icons/settingsConnections.svg?react'
import SettingsDisplay from './assets/icons/settingsDisplay.svg?react'
import SettingsLights from './assets/icons/settingsLights.svg?react'
import SettingsMidMetering from './assets/icons/settingsMidMetering.svg?react'
import SettingsOcppSettings from './assets/icons/settingsOcppSettings.svg?react'
import SettingsPowerDetails from './assets/icons/settingsPowerDetails.svg?react'
import SettingsPowerOptions from './assets/icons/settingsPowerOptions.svg?react'
import SettingsRfid from './assets/icons/settingsRfid.svg?react'
import SettingsSpeakerOptions from './assets/icons/settingsSpeakerOptions.svg?react'
import Amper from './assets/icons/amper.svg?react'
import Home from './assets/icons/home.svg?react'
import Voltage from './assets/icons/voltage.svg?react'
import Thermometer from './assets/icons/thermometer.svg?react'
import Humidity from './assets/icons/humidity.svg?react'
import Dim from './assets/icons/dim.svg?react'
import Bright from './assets/icons/bright.svg?react'
import Warning from './assets/icons/warning.svg?react'
import Wifi from './assets/icons/wifi.svg?react'
import Ethernet from './assets/icons/ethernet.svg?react'
import Cellular from './assets/icons/cellular.svg?react'
import Gear from './assets/icons/gear.svg?react'
import Copy from './assets/icons/copy.svg?react'
import Json from './assets/icons/json.svg?react'
import AppleLogoSmall from './assets/icons/appleLogoSmall.svg?react'
import GoogleLogoSmall from './assets/icons/googleLogoSmall.svg?react'
import Download from './assets/icons/download.svg?react'
import Check from './assets/icons/check.svg?react'
import SolarEcoMode from './assets/icons/solarEcoMode.svg?react'
import SolarGreenMode from './assets/icons/solarGreenMode.svg?react'


const icons: {[key: string]: {[key: string]: FC}} = {
  general: {
    search: Search,
    refresh: Refresh,
    edit: Edit,
    charger: Charger,
    backArrow: BackArrow,
    forwardArrow: ForwardArrow,
    upArrow: UpArrow,
    downArrow: DownArrow,
    logoDashboardHorizontal: LogoDashBoardHorizontal,
    logoDashboardVertical: LogoDashBoardVertical,
    x: X,
    export: Export,
    wrench: Wrench,
    trash: Trash,
    filter: Filter,
    upload: Upload,
    plus: Plus,
    more: More,
    log: Log,
    error: Error,
    checkboxFull: CheckboxFull,
    checkboxEmpty: CheckboxEmpty,
    calendar: Calendar,
    warning: Warning,
    download: Download,
    check: Check
  },
  menu: {
    dashboard: MenuDashboard,
    dashboardActive: MenuDashboardActive,
    chargers: MenuChargers,
    chargersActive: MenuChargersActive,
    chargergroups: MenuChargergroups,
    chargergroupsActive: MenuChargergroupsActive,
    logs: MenuLogs,
    logsActive: MenuLogsActive,
    appversion: MenuAppversion,
    appversionActive: MenuAppversionActive,
    users: MenuUsers,
    usersActive: MenuUsersActive,
    settings: MenuSettings,
    settingsActive: MenuSettingsActive,
    notifications: MenuNotifications,
    notificationsActive: MenuNotificationsActive,
    rfids: MenuRfids,
    rfidsActive: MenuRfidsActive
  },
  app: {
    flagHun: FlagHun,
    flagUk: FlagUk,
    lightmode: Lightmode,
    darkmode: Darkmode,
    systemTheme: Systemtheme,
    mail: Mail,
    key: Key
  },
  login: {
    flagHun: FlagHun,
    flagUk: FlagUk,
    lightmode: Lightmode,
    darkmode: Darkmode,
    systemTheme: Systemtheme,
    mail: Mail,
    chargerFace: ChargerFace,
    chargerEye: ChargerEye,
    chargerWink: ChargerWink,
    eye: Eye,
    eyeDisabled: EyeDisabled,
    forgotPassword: ForgotPassword,
    checkYourInbox: CheckYourInbox,
    setNewPassword: SetNewPassword,
    appleLogoSmall: AppleLogoSmall,
    googleLogoSmall: GoogleLogoSmall
  },
  chargers: {
    chargerOnline: ChargerOnline,
    chargerOffline: ChargerOffline,
    user: User,
    commands: Commands,
    pluggedIn: PluggedIn,
    unplugged: Unplugged,
    chargerStatusOnline: ChargerStatusOnline,
    chargerStatusOffline: ChargerStatusOffline,
    chargerStatusCharging: ChargerStatusCharging,
    chargerStatusError: ChargerStatusError,
    chargerStatusBlocked: ChargerStatusBlocked,
    power: Power,
    energy: Energy,
    time: Time,
    average: Average,
    sleep: Sleep,
    normalCharging: NormalCharging,
    kWhBasedCharging: KWhBasedCharging,
    scheduledCharging: ScheduledCharging,
    rfidTag: RfidTag,
    flagHun: FlagHun,
    flagUk: FlagUk,
    thermometer: Thermometer,
    humidity: Humidity
  },
  settings: {
    powerDetails: SettingsPowerDetails,
    display: SettingsDisplay,
    lights: SettingsLights,
    speakerOptions: SettingsSpeakerOptions,
    powerOptions: SettingsPowerOptions,
    connections: SettingsConnections,
    midMetering: SettingsMidMetering,
    rfid: SettingsRfid,
    ocppSettings: SettingsOcppSettings,
    calibrateEvse: Datasheet,
    wifi: Wifi,
    ethernet: Ethernet,
    cellular: Cellular,
    dim: Dim,
    bright: Bright,
    amper: Amper,
    home: Home,
    voltage: Voltage,
    thermometer: Thermometer,
    humidity: Humidity,
    gear: Gear,
    solarEcoMode: SolarEcoMode,
    solarGreenMode: SolarGreenMode
  },
  chargerGroups: {
    SW: SW,
    FW: FW,
    OS: OS,
    chargerOnline: ChargerOnline,
    chargerOffline: ChargerOffline,
    installed_chargersGroup: Installed_chargersGroup,
    betatesterGroup: BetatesterGroup,
    defaultGroup: DefaultGroup,
    insider_simuhatGroup: Insider_simuhatGroup,
    chargerGroup: ChargerGroup,
    fileDropUpload: FileUpload,
    datasheet: Datasheet,
    move: MoveCharger
  },
  appversions: {
    appleLogo: AppleLogo,
    androidLogo: AndroidLogo,
    minAppVersion: MinAppVersion,
    latestAppVersion: LatestAppVersion
  },
  notification: {
    copy: Copy,
    json: Json
  }
}

export default function Icon({ path, color, showAllIconsDEV, ...props }: { path: string, color?: string, showAllIconsDEV?: boolean, width?: string, height?: string, onClick?: (() => void) | ((e: any) => void), margin?: string, margintop?: string, marginleft?: string, marginbottom?: string, marginright?: string, style?: any }) {
  const theme = useTheme()

  if (showAllIconsDEV) {
    const allIcons = []
    for (const group in icons) {
      for (const key in icons[group]) {
        allIcons.push({component: icons[group][key], label: `${group}.${key}`})
      }
    }
    return <div style={{ height: '90vh', overflow: 'scroll' }}>{allIcons.map(Iconn => (
      <>
        <div style={{ display: "flex" }}>
          {/*@ts-expect-error TS thinks that there is no fill prop, this is wrong*/}
          <Iconn.component width="24px" height="24px" style={{ border: "1px solid lightGray" }} />
          <WhiteSpace width="8px" />
          <span>{Iconn.label}</span>
        </div>
        <br />
        <WhiteSpace height="8px" />
      </>
    ))}</div>
  } else {
    if (icons[path.split('.')[0]] && icons[path.split('.')[0]][path.split('.')[1]]) {
      const Component = icons[path.split('.')[0]][path.split('.')[1]]
      //@ts-expect-error TS thinks that there is no fill prop, this is wrong
      return Component ? <Component fill={color ? theme.colors[color] || color : theme.colors.gray_900} style={{ cursor: props.onClick && "pointer", marginTop: props.margintop || null, marginBottom: props.marginbottom || null, marginLeft: props.marginleft || null, marginRight: props.marginright || null, width: props.width, height: props.height }} {...{width: '', height: '', ...props}} /> : null
    } else {
      if (path.split('.')[0] == 'general') {
        console.log(`Unknown icon: ${path}`)
      }
      return <div {...props} style={props.onClick ? { width: props.width, height: props.height, backgroundColor: "red", cursor: "pointer" } : { width: "16px", height: "16px", backgroundColor: "red" }}></div>
    }
  }
}