import { useState } from "react"
import { useTranslation } from "react-i18next"
import ButtonWithIcon from "../components/ButtonWithIcon"
import downloadObjectInBrowser from "../utils/downloadObjectInBrowser"
import writeXlsxFile from "write-excel-file"

interface TitleSchema {
  label: string
  width: string
}

export default function ExportButton({ titleRow, data, fileName, handleAfterDone, modalOnly }: { titleRow: TitleSchema[], data: (string | number)[][], fileName: string, handleAfterDone?: () => void, modalOnly?: boolean }) {
  const { t } = useTranslation()

  const [selectedFormats] = useState<string[]>(['.XLSX'])

  async function handleExport() {
    if (selectedFormats.includes('.JSON')) {
      const result = []
      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        result.push({})
        for (let columnIndex = 0; columnIndex < data[rowIndex].length; columnIndex++) {
          (result[rowIndex] as any)[titleRow[columnIndex].label] = data[rowIndex][columnIndex]
        }
      }
      downloadObjectInBrowser(result, `${fileName} ${new Date().toLocaleString('hu')}`)
    }

    if (selectedFormats.includes('.XLSX')) {
      const result = []
      result.push(titleRow.map(value => ({ value: value.label, fontWeight: 'bold' })))
      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        result.push(data[rowIndex].map(value => ({ value })))
      }

      const columns = titleRow.map(value => ({ width: value.width }))
      //@ts-expect-error result is not of type SheetData[], this is fine, they are identical
      await writeXlsxFile(result, { columns, fileName: `${fileName} ${new Date().toLocaleString('hu')}.xlsx` })
    }

    handleAfterDone && handleAfterDone()
  }


  return (
    <>
      {!modalOnly && (
        <ButtonWithIcon icon="general.export" label={t('chargerLogs.export')} onClick={handleExport} backgroundColor="white" />
      )}
    </>
  )
}
