import { initializeApp } from "firebase/app"
//import { getAnalytics } from "firebase/analytics"

// DEV
/*const firebaseConfig = {
  apiKey: "AIzaSyByh2I1r0OTHuaKKnTfbjp87pPveynBJxE",
  authDomain: "voltie-dev.firebaseapp.com",
  databaseURL: "https://voltie-dev-default-rtdb.firebaseio.com",
  projectId: "voltie-dev",
  storageBucket: "voltie-dev.appspot.com",
  messagingSenderId: "402186632915",
  appId: "1:402186632915:web:c9ca208d2a0f0fbfddde26",
  measurementId: "G-28BC52YC0M"
}*/

// PROD
const firebaseConfig = {
  apiKey: "AIzaSyBtNQhnIcWxr9ny4pklYxkXYKTl-RlO8_M",
  authDomain: "voltie-charger.firebaseapp.com",
  databaseURL: "https://voltie-charger-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "voltie-charger",
  storageBucket: "voltie-charger.appspot.com",
  messagingSenderId: "130173012234",
  appId: "1:130173012234:web:834e34c16349dbc0dd52f3",
  measurementId: "G-FP0Q1FY5VN"
}

const fbConf = initializeApp(firebaseConfig)
// Only activate this after the cookie policy and prompt are visible on the site
//getAnalytics(fbConf)
export default fbConf
