import { useTranslation } from "react-i18next"
import PageTitle from "../components/PageTitle"
import { useOutletContext } from "react-router-dom"
import ChargerType from "../types/ChargerType"
import Comm from "../Comm"
import { useEffect, useState } from "react"
import RfidType from "../types/RfidType"
import Table from "../modules/Table"
import { TableCellTypes } from "../enums/TableCellTypes"
import { child, get, getDatabase, ref } from "firebase/database"
import fbConf from "../FirebaseConf"
import styled from "styled-components"
import ExportButton from "../modules/ExportButton"
import LoaderDots from "../components/LoaderDots"

const ToolbarContaier = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
`

const EmptyTableLabel = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.gray_900};
  margin-top: 48px;
  position: absolute;
`

export default function Rfids() {
  const { t } = useTranslation()
  const { isAdmin } = useOutletContext<{ isAdmin: boolean }>()

  const [rfids, setRfids] = useState<{ chargerId: string, chargerName: string, rfid: RfidType }[]>()

  async function fetchRfids() {
    let chargers: ChargerType[] = []

    if (isAdmin) {
      const response = await Comm('/api-charger/chargers', {})
      if (response.status != 200) { return }
      chargers = await response.json()
    } else {
      const response = await Comm('/api-charger/user/chargers', {})
      if (response.status != 200) { return }
      chargers = await response.json()

      // Get users chargers from firebase to get the firebase charger name
      const firebaseChargerNamesResponse = await get(child(ref(getDatabase(fbConf)), `voltie/chargerusers/${chargers[0].userId}/chargers`))
      const firebaseChargerNames = await firebaseChargerNamesResponse.val()
      for (const charger of chargers) {
        charger.localName = firebaseChargerNames[charger.chargerId]?.name || charger.localName
      }
    }

    // Get all online chargers, so we dont ask for RFIDs on offline chargers for no reason
    const onlineChargersResponse = await Comm('/api-websocket/online', {})
    const onlineChargers = await onlineChargersResponse.json()

    let rfids: { chargerId: string, chargerName: string, rfid: RfidType }[] = []
    for (const charger of chargers) {

      if (onlineChargers.includes(charger.chargerId)) {
        const rfidsResponse = await Comm('/api-websocket/mobile', {
          method: "POST",
          headers: {
            "Charger_id": charger.chargerId,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ "msg_type": 23 })
        })
        if (rfidsResponse.ok) {
          const rfidResult = await rfidsResponse.json()

          for (const rfid of rfidResult.rfid_list) {
            rfids.push({ chargerId: charger.chargerId, chargerName: charger.localName, rfid })
          }
        }
      }
    }
    setRfids(rfids)
  }

  useEffect(() => {
    if (isAdmin) {
      setTimeout(() => {
        if (confirm("Admin jogosultságokkal rendelkezel.\nHa az OK gombra kattintasz, minden online töltőtőnek ki fogunk küldeni egy requestet. Ez akár 1-2 percbe is telhet.\nBiztosan szeretnéd folytatni?") == true) {
          fetchRfids()
        } else {
          setRfids([])
        }
      }, 100)
    } else {
      fetchRfids()
    }
  }, [])

  return <>
    <PageTitle label={t('rfids.rfids')} />

    <ToolbarContaier>
      <div>
        {rfids && <ExportButton fileName="RFIDs" titleRow={[
          { label: 'ID', width: '40' },
          { label: 'Name', width: '35' },
          { label: 'Enabled', width: '20' },
          { label: 'Email', width: '40' },
          { label: 'Last Use', width: '20' },
          { label: 'Charger ID', width: '20' }
        ]} data={rfids.map(rfid => [rfid.rfid.id, rfid.rfid.name, rfid.rfid.enabled ? 'Enabled' : 'Disabled', rfid.rfid.email, rfid.rfid.last_use ? new Date(rfid.rfid.last_use * 1000).toLocaleString('hu') : '', rfid.chargerId])} />}
      </div>
    </ToolbarContaier>

    {rfids && rfids.length > 0 ? <>
      <Table id="Rfids" height="calc(90vh - 170px)" isSearchEnabled={true} isPaginationEnabled={true} columns={[
        { width: '30px' },
        { label: t('rfids.charger'), width: '17%' },
        { label: t('charger.rfidName'), width: '25%' },
        { label: t('charger.rfidState'), width: '10%' },
        { label: t('charger.rfidNumber'), width: '15%' },
        { label: t('charger.lastUse'), width: '10%' },
        { label: t('charger.user') },
      ]} data={rfids.map(rfid => [
        {
          link: `/chargers/${rfid.chargerId}/usersRfid`,
          value: {
            type: TableCellTypes.Image,
            src: 'chargers.rfidTag'
          }
        }, {
          link: `/chargers/${rfid.chargerId}/usersRfid`,
          sortBy: rfid.chargerId || '',
          value: {
            type: TableCellTypes.LabelDivided,
            value1: rfid.chargerName || '-',
            value2: rfid.chargerId || '-'
          }
        }, {
          link: `/chargers/${rfid.chargerId}/usersRfid`,
          sortBy: rfid.rfid.name,
          value: {
            type: TableCellTypes.Label,
            value: rfid.rfid.name || '-'
          }
        }, {
          link: `/chargers/${rfid.chargerId}/usersRfid`,
          sortBy: rfid.rfid.enabled ? 'a' : 'b',
          value: {
            type: TableCellTypes.Status,
            label: rfid.rfid.enabled ? t('charger.active') : t('charger.inactive'),
            themeColor: rfid.rfid.enabled ? 'success' : 'gray_400'
          }
        }, {
          link: `/chargers/${rfid.chargerId}/usersRfid`,
          sortBy: rfid.rfid.id,
          value: {
            type: TableCellTypes.Label,
            value: rfid.rfid.id || '-'
          }
        }, {
          link: `/chargers/${rfid.chargerId}/usersRfid`,
          sortBy: rfid.rfid.last_use || 0,
          value: {
            type: TableCellTypes.LabelDivided,
            value1: rfid.rfid.last_use ? new Date(rfid.rfid.last_use * 1000).toLocaleDateString('hu') : '-',
            value2: rfid.rfid.last_use ? new Date(rfid.rfid.last_use * 1000).toLocaleTimeString('hu') : ''
          }
        }, {
          link: `/chargers/${rfid.chargerId}/usersRfid`,
          sortBy: rfid.rfid.email || '',
          value: {
            type: TableCellTypes.Label,
            value: rfid.rfid.email || '-'
          }
        }
      ])} />
    </> : rfids && rfids.length == 0 ? <>
      <Table id="UsersRfids_Rfids" height="calc(90vh - 170px)" isSearchEnabled={true} isRenderEmpty={true} columns={[
        { width: '30px' },
        { label: t('rfids.charger'), width: '17%' },
        { label: t('charger.rfidName'), width: '25%' },
        { label: t('charger.rfidState'), width: '10%' },
        { label: t('charger.rfidNumber'), width: '15%' },
        { label: t('charger.lastUse'), width: '10%' },
        { label: t('charger.user') },
      ]} data={[[{}, {}, {}, {}, {}, {}, {}]]} /></> : <>
      <EmptyTableLabel><LoaderDots /></EmptyTableLabel>
    </>}
  </>
}