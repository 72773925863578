import { useEffect, useRef } from "react"
import styled from "styled-components"

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.gray_700};
  opacity: .6;
  z-index: 10000;
`

const Container = styled.div<{ $align?: string }>`
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  ${props => {
    if (props.$align == 'left') {
      return 'left: 16px;'
    } else if (props.$align == 'right') {
      return 'right: 16px;'
    } else {
      return 'left: 50%; transform: translate(-50%, -50%);'
    }
  }}
  box-shadow: 0px 4px 4px -4px #0C0C0D0D;
  background-color: ${props => props.theme.colors.background};
  border-radius: 24px;
  z-index: 10001;
`

export default function Modal({ children, align, onEscape }: { children: React.ReactNode, align?: string, onEscape: () => void }) { // Align: left / right / null (center)
  const containerRef = useRef<HTMLDivElement | null>(null)

  // Focus the conteiner as soon as it loads, so the user can close it with ESC without clicking in it
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus()
    }
  }, [])

  return (
    <>
      <Background />
      <Container $align={align} ref={containerRef} tabIndex={1} onKeyDown={e => e.key == 'Escape' && onEscape()}>
        {children}
      </Container>
    </>
  )
}