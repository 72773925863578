import { useTranslation } from "react-i18next"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components"
import { useEffect, useState } from "react"
import Comm from "../../Comm"
import WhiteSpace from "../../components/Whitespace"
import ChargerType from "../../types/ChargerType"
import Modal from "../../components/Modal"
import Button from "../../components/Button"
import InputWithLabel from "../../components/InputWithLabel"
import dateToTimeAgoString from "../../utils/dateToTimeAgoString"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import { useOutletContext } from "react-router-dom"
import { child, get, getDatabase, ref } from "firebase/database"
import fbConf from "../../FirebaseConf"
import ExportButton from "../../modules/ExportButton"

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const ToolbarContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 12px;
  display: flex;
`

const SmallModalContainer = styled.div`
  padding: 24px;
`

const SmallModalButtonContainer = styled.div`
  width: 15vw;
  display: flex;
`

export default function Chargers() {
  const { t } = useTranslation()
  const { isAdmin } = useOutletContext<{ isAdmin: boolean }>()

  const [chargers, setChargers] = useState<ChargerType[]>()
  const [lastRefresh, setLastRefresh] = useState<string>('')
  const [isEditingChargerValues, setIsEditingChargerValues] = useState<ChargerType | null>()
  const [newSerialNumberInputValue, setNewSerialNumberInputValue] = useState<string>('')
  const [newChargerIdInputValue, setNewChargerIdInputValue] = useState<string>('')
  const [newLocalNameInputValue, setNewLocalNameInputValue] = useState<string>('')

  const [triggerTableSearchAgain, setTriggerTableSearchAgain] = useState<number>(0)

  async function fetchChargersForAdmin() {
    const response = await Comm('/api-charger/chargers', {})
    if (response.status != 200) { throw new Error('Failed to fetch chargers for admin, response is not 200') }
    const result = await response.json()
    setLastRefresh(new Date().toString().split(' ')[4])

    // Iterare over all chargers and set the online field based on the online endpoint
    const onlineChargersResponse = await Comm('/api-websocket/online', {})
    if (response.status != 200) { throw new Error('Failed to fetch chargers for admin, response is not 200') }
    const onlineChargers = await onlineChargersResponse.json()
    for (const charger of result) {
      charger.online = onlineChargers.includes(charger.chargerId)
    }

    setChargers(result)
  }

  async function fetchChargersForUser() {
    const response = await Comm('/api-charger/user/chargers', {})
    if (response.status != 200) { throw new Error('Failed to fetch chargers for user, response is not 200') }
    const result = await response.json()
    setLastRefresh(new Date().toString().split(' ')[4])

    if (result.length > 0) {
      // Get users chargers from firebase to get the firebase charger name
      const firebaseChargerNamesResponse = await get(child(ref(getDatabase(fbConf)), `voltie/chargerusers/${result[0].userId}/chargers`))
      const firebaseChargerNames = await firebaseChargerNamesResponse.val()

      // Iterare over all chargers and set the online field based on the online endpoint
      const onlineChargersResponse = await Comm('/api-websocket/online', {})
      if (onlineChargersResponse.status != 200) { throw new Error('Failed to fetch online for user, response is not 200') }
      const onlineChargers = await onlineChargersResponse.json()
      for (const charger of result) {
        charger.online = onlineChargers.includes(charger.chargerId)
        charger.localName = firebaseChargerNames[charger.chargerId]?.name || charger.localName
      }
    }

    setChargers(result)
  }

  useEffect(() => {
    handelRefresh()
  }, [])

  function handelRefresh() {
    setChargers([])

    if (isAdmin) {
      fetchChargersForAdmin()
    } else {
      fetchChargersForUser()
    }

  }

  async function handleEditChargerValues() {
    if (isEditingChargerValues) {
      await Comm('/api-charger/charger/update', {
        method: 'PUT',
        body: JSON.stringify({
          chargerId: isEditingChargerValues.chargerId,
          serialNumber: newSerialNumberInputValue.length > 0 ? newSerialNumberInputValue : ' ',
          firmwareVersion: '',
          softwareVersion: '',
          osVersion: '',
          localName: newLocalNameInputValue.length > 0 ? newLocalNameInputValue : ' ',
          initedCharger: {
            chargerId: '',
            userId: ''
          }
        }),
        headers: {
          'charger_id': isEditingChargerValues.chargerId,
          'Content-Type': 'application/json'
        }
      })
      isEditingChargerValues.serialNumber = newSerialNumberInputValue
      isEditingChargerValues.chargerId = newChargerIdInputValue
      isEditingChargerValues.localName = newLocalNameInputValue
      setNewSerialNumberInputValue('')
      setNewChargerIdInputValue('')
      setNewLocalNameInputValue('')
      setIsEditingChargerValues(null)

      setTriggerTableSearchAgain((prev) => prev + 1)
    }
  }

  // Set the values of the 3 input fields when editing a chargers values begins
  useEffect(() => {
    if (isEditingChargerValues) {
      setNewSerialNumberInputValue(isEditingChargerValues.serialNumber || '')
      setNewChargerIdInputValue(isEditingChargerValues.chargerId || '')
      setNewLocalNameInputValue(isEditingChargerValues.localName || '')
    }
  }, [isEditingChargerValues])

  return (
    <>
      <PageTitle label={t('chargers.chargers')} />
      {isEditingChargerValues && (
        <Modal onEscape={() => setIsEditingChargerValues(null)}>
          <SmallModalContainer>
            <InputWithLabel autoFocus width='100%' label={t('chargers.newChargerId')} value={newChargerIdInputValue} onInput={() => { }} />
            <WhiteSpace height="16px" />
            <InputWithLabel width='100%' label={t('chargers.newSerialNumber')} value={newSerialNumberInputValue} onInput={(value) => setNewSerialNumberInputValue(value)} />
            <WhiteSpace height="16px" />
            <InputWithLabel width='100%' label={t('chargers.newLocalName')} value={newLocalNameInputValue} onInput={(value) => setNewLocalNameInputValue(value)} />
            <WhiteSpace height="16px" />
            <SmallModalButtonContainer>
              <Button onclick={handleEditChargerValues} label={t('chargers.save')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsEditingChargerValues(null)} label={t('chargers.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      <ToolbarContainer>
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('chargers.refresh')} onClick={handelRefresh} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastRefresh}</RefreshTimerLabel>
        </div>
        <WhiteSpace width="8px" />
        <div>
          {chargers && <ExportButton fileName="Chargers" titleRow={[
            { label: 'Charger ID', width: '20' },
            { label: 'Serial Number', width: '15' },
            { label: 'Charger Name', width: '30' },
            { label: 'Online', width: '10' },
            { label: 'Last Check-in', width: '20' },
            { label: 'Owner Email', width: '40' },
            { label: 'FW Version', width: '15' },
            { label: 'SW Version', width: '15' },
            { label: 'OS Version', width: '15' },
          ]} data={chargers.map(charger => [charger.chargerId, charger.serialNumber || ' ', charger.localName, charger.online ? 'Online' : 'Offline', charger.lastPresence, charger.userEmail, charger.fwVersion, charger.swVersion, charger.osVersion])} />
          }
        </div>
      </ToolbarContainer>
      {isAdmin ? <>
        {/* Chargers table for admins */}
        {chargers && chargers.length > 0 && (
          <Table id="Chargers" height="calc(90vh - 170px)" isSearchEnabled={true} isPaginationEnabled={true} triggerRefresh={triggerTableSearchAgain} columns={[
            { width: '30px' },
            { label: t('chargers.chargerId'), width: '13%' },
            { label: t('chargers.userIdUserEmail'), width: '20%' },
            { label: t('chargers.status'), width: '8%' },
            { label: t('chargers.lastOnline'), width: '9%' },
            { label: t('chargers.registrationDate'), width: '9%' },
            { label: t('chargers.fwVersion'), width: '6%' },
            { label: t('chargers.swVersion'), width: '6%' },
            { label: t('chargers.osVersion'), width: '6%' },
            { label: t('chargers.serialNumberLocalName') },
            { width: '12px' },
            { width: '70px' }
          ]} data={chargers.map(charger => [
            {
              link: `/chargers/${charger.chargerId}`,
              value: {
                type: TableCellTypes.Image,
                src: charger.online ? 'chargers.chargerOnline' : 'chargers.chargerOffline'
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.chargerId,
              value: {
                type: TableCellTypes.Label,
                value: charger.chargerId
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.userId,
              value: {
                type: TableCellTypes.LabelDivided,
                value1: charger.userId,
                value2: charger.userEmail
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.online ? -Infinity : new Date(charger.lastPresence.replace(/\./g, '-')).valueOf(),
              value: {
                type: TableCellTypes.Status,
                label: charger.online ? 'Online' : 'Offline',
                themeColor: charger.online ? 'success' : 'gray_400',
                labelBelow: charger.online ? null : dateToTimeAgoString(new Date(charger.lastPresence.replace(/\./g, '-')))
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.lastPresence,
              value: {
                type: TableCellTypes.LabelDivided,
                value1: charger.lastPresence.split(' ')[0],
                value2: charger.lastPresence.split(' ')[1]
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.registrationDate,
              value: {
                type: TableCellTypes.LabelDivided,
                value1: charger.registrationDate.split(' ')[0],
                value2: charger.registrationDate.split(' ')[1]
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: parseInt(charger.fwVersion.split('.')[0]) * 100 + parseInt(charger.fwVersion.split('.')[1]) || 0,
              value: {
                type: TableCellTypes.Label,
                value: charger.fwVersion || '-'
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: parseInt(charger.swVersion.split('.')[0]) * 10000 + parseInt(charger.swVersion.split('.')[1]) * 100 + parseInt(charger.swVersion.split('.')[2]) || 0,
              value: {
                type: TableCellTypes.Label,
                value: charger.swVersion || '-'
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: parseInt(charger.osVersion) || 0,
              value: {
                type: TableCellTypes.Label,
                value: charger.osVersion || '-'
              }
            }, {
              onClick: () => setIsEditingChargerValues(charger),
              sortBy: charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-',
              value: {
                type: TableCellTypes.LabelDivided,
                value1: charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-',
                value2: charger.localName || '-'
              }
            }, {
              onClick: () => setIsEditingChargerValues(charger),
              value: {
                type: TableCellTypes.Icon,
                src: 'general.edit',
                color: 'gray_900'
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              value: {
                type: TableCellTypes.OpenButton
              }
            }
          ])} />
        )}
      </> : <>
        {/* Chargers table for users */}
        {chargers && chargers.length > 0 && (
          <Table id="Chargers" height="calc(90vh - 170px)" isSearchEnabled={true} isPaginationEnabled={true} triggerRefresh={triggerTableSearchAgain} columns={[
            { width: '30px' },
            { label: t('chargers.chargerName'), width: '25%' },
            { label: t('chargers.chargerId'), width: '25%' },
            { label: t('chargers.status'), width: '25%' },
            { label: t('chargers.lastOnline') },
            { width: '70px' }
          ]} data={chargers.map(charger => [
            {
              link: `/chargers/${charger.chargerId}`,
              value: {
                type: TableCellTypes.Image,
                src: charger.online ? 'chargers.chargerOnline' : 'chargers.chargerOffline'
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.localName || '-',
              value: {
                type: TableCellTypes.Label,
                value: charger.localName || '-'
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.chargerId,
              value: {
                type: TableCellTypes.Label,
                value: charger.chargerId
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.online ? -Infinity : new Date(charger.lastPresence.replace(/\./g, '-')).valueOf(),
              value: {
                type: TableCellTypes.Status,
                label: charger.online ? 'Online' : 'Offline',
                themeColor: charger.online ? 'success' : 'gray_400',
                labelBelow: charger.online ? null : dateToTimeAgoString(new Date(charger.lastPresence.replace(/\./g, '-')))
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              sortBy: charger.lastPresence,
              value: {
                type: TableCellTypes.LabelDivided,
                value1: charger.lastPresence.split(' ')[0],
                value2: charger.lastPresence.split(' ')[1]
              }
            }, {
              link: `/chargers/${charger.chargerId}`,
              value: {
                type: TableCellTypes.OpenButton
              }
            }
          ])} />
        )}
      </>}
    </>
  )
}